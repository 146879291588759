import React, {Component} from 'react';

import SimpleCard from "./card";
import ButtonAppBar from "../appBar";
import CircularProgress from "@material-ui/core/CircularProgress";
import {withRouter} from 'react-router-dom';
import Network from "../../network/network";
import UsersApi from "../../network/usersApi";

const KEY_APP_STATE = "appState";

class Users extends Component {

    constructor(props) {
        super(props);
        this.state = localStorage.getItem(KEY_APP_STATE) ? JSON.parse(localStorage.getItem(KEY_APP_STATE)) : this.state;

        if (!!this.props.location.state) {
            let privileges = this.props.location.state.privileges
            this.state.privileges = privileges;
        }

        localStorage.removeItem(KEY_APP_STATE);
    }

    render() {
        return (
            <div>
                <div style={{marginTop: '30%', position: "fixed", width: '100%', textAlign: 'center'}}>
                    {this.isUserLiseEmpty() ? <CircularProgress/> : null}
                </div>
                {<ButtonAppBar searchTextChanged={this.searchTextChanged}
                               filterWasSet={this.filterWasSet}
                               payingSwitchEnabled={this.state.payingSwitchEnabled}
                               payingSwitchLabel={this.PayingSwitchLabel}
                               onPayingSwitchChange={this.onPayingSwitchChange}
                               claimCheckboxEnabled={this.state.claimCheckboxEnabled}
                               onClaimCheckboxChange={this.onClaimCheckboxChange}>

                </ButtonAppBar>}
                <div>
                    {this.state.usersToShow.map((contact) => (
                        <SimpleCard contact={contact} privileges={this.state.privileges}/>
                    ))}
                </div>
            </div>
        )
    }

    PayingSwitchLabel = () => {
        return this.state.payingSwitchEnabled
            ?
            `Paying (${this.state.usersToShow.length})`
            :
            `Non-Paying (${this.state.usersToShow.length})`
    }

    onPayingSwitchChange = (checked) => {
        this.setState({payingSwitchEnabled: checked})
        this.filterPaying(checked);
    }

    onClaimCheckboxChange = (checked) => {
        this.setState({claimCheckboxEnabled: checked})
        this.filterClaim(checked);
    }

    filterPaying = (paying) => {
        let newUsers = this.state.users;
        newUsers = newUsers.filter(user => paying ? this.isPayingUser(user) : !this.isPayingUser(user));

        if (newUsers.length > 0) {
            this.setState({usersToShow: newUsers})
        }
    }

    filterClaim = (claimChecked) => {
        let newUsers = this.state.users;
        newUsers = newUsers.filter(user => claimChecked === user.isClaimed && this.isPayingUser(user) === this.state.payingSwitchEnabled);

        if (newUsers.length > 0) {
            this.setState({usersToShow: newUsers})
        }
    }

    isUserLiseEmpty() {
        return this.state.usersToShow.length === 0;
    }

    searchTextChanged = (newText) => {
        let newUsers = this.state.users;

        if (newText === 'convert') {
            newUsers = newUsers.filter(user => user.numOfCustomers === 0);
        } else if (newText === 'web') {
            newUsers = newUsers.filter(user => user.clientType === 'web');
        } else if (newText === 'paying') {
            newUsers = newUsers.filter(user => this.isPayingUser(user));
        } else if (newText.toLowerCase() === 'america') {
            newUsers = newUsers.filter(user => user.timeZone.toLowerCase().includes('america'));
        } else if (newText.toLowerCase() === 'asia') {
            newUsers = newUsers.filter(user => user.timeZone.toLowerCase().includes('asia'));
        } else {
            newUsers = newUsers.filter(user =>
                (!!user.userName &&
                    user.userName.toLowerCase().includes(newText.toLowerCase()))
                || (!!user.appVersion && user.appVersion.startsWith(newText.toLowerCase()))
                || user.email.toLowerCase().includes(newText.toLowerCase())
                || user._id.includes(newText)
            );
        }

        if (newUsers.length > 0) {
            this.setState({usersToShow: newUsers})
        } else {
            this.setState({
                usersToShow: [{
                    userName: "No Results"
                }]
            })
        }


        // user=> user.email.includes(newText) || user.userName.includes(newText) || user._id.includes(newText) || user.profession.includes(newText)
    };

    isPayingUser = function (user) {
        return user.pricingPlanId > 0;
    }

    filterWasSet = (newFilter) => {
        console.log(newFilter);
        this.setState({currentFilter: newFilter});

    };

    state = {
        users: [],
        usersToShow: [],
        dataItem: [],
        currentFilter: 'name',
        privileges: null,
        payingSwitchEnabled: false,
        claimCheckboxEnabled: false
    };

    onUnload = e => {
        e.preventDefault();
        console.log("Saving state: ", this.state);
        localStorage.setItem(KEY_APP_STATE, JSON.stringify(this.state));
    };

    componentDidMount() {
        window.addEventListener("beforeunload", this.onUnload);

        if (this.isUserLiseEmpty()) {
            UsersApi.getInstance().fetchUsers()
                .then(function (data) {
                    try {
                        console.log("users len: ", data.length);
                        this.setState({users: data});
                        this.setState({usersToShow: data});
                        this.filterPaying(this.state.payingSwitchEnabled);
                        this.filterClaim(this.state.claimCheckboxEnabled);
                    } catch (e) {
                        console.log(e)
                    }
                }.bind(this))
        }
    }


}


export default withRouter(Users);
