import React, {Component} from 'react';

import SimpleTable from "./dashboardTable";
import CircularProgress from '@material-ui/core/CircularProgress';
import {Doughnut} from 'react-chartjs-2';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from "@material-ui/core/Button";
import ReportButton from "../report/reportButton";
import Network from "../../network/network";

function getArrayLenByTz(list, tz){
    if(tz === "Foreign"){
        return list.filter(x => x.timeZone !== "Asia/Jerusalem").length
    }
    else{
        return list.filter(x => x.timeZone === tz).length
    }

}

function getFunnelBreakString(list, tz){
    if(tz === "Foreign"){
        return `${list.filter(x => x.timeZone !== "Asia/Jerusalem").length}`
    }
    else{
        return `${list.filter(x => x.timeZone === tz).length}`
    }

}


function getPrvRateString(previous) {
    var returnStr = "   ";
    if(previous > 100){
        returnStr += "(+" + (previous - 100).toFixed(1) + "%)"
    }
    else{
        returnStr += "(-" + (100 - previous).toFixed(1) + "%)"
    }

    return returnStr;
}

class Dashboard extends Component {

    state = {
        dataItem: [],
        healthRow: [],
        //featuresRow: [],
        eventsRow : [],
        referralsRow : [],
        revenueRow : [],
        professionsForChart: [],
        usersRow: [],
        funnelRowIL: [],
        funnelRowForeign: [],
        loyals: [],
        churned: [],
        newLoyals: [],
        test: {}
    };

    render() {

        return (

            <Container fixed>

                <div>
                    {this.state.dataItem.length === 0 ?
                        <div style={{marginTop: '30%', position: "fixed", width: '100%', textAlign: 'center'}}>
                            <CircularProgress/>
                        </div>

                        :
                        <div>


                        <SimpleTable style={{marginTop: '10px'}} rows={this.state.revenueRow} titles={["Revenues"]}/>
                        <SimpleTable style={{marginTop: '10px'}} rows={this.state.usersRow} titles={["Users"]}/>
                        <SimpleTable style={{marginTop: '10px'}} rows={this.state.funnelRowIL} titles={["Funnel IL - last 30 days"]}/>
                        <SimpleTable style={{marginTop: '10px'}} rows={this.state.funnelRowForeign} titles={["Funnel Foreign - last 30 days"]}/>
                        <SimpleTable style={{marginTop: '10px'}} rows={this.state.healthRow} titles={["SMS's Sent Today"]}/>
                        {/*<SimpleTable style={{marginTop: '10px'}} rows={this.state.featuresRow} titles={["Features Adaption"]}/>*/}
                        <SimpleTable style={{marginTop: '10px'}} rows={this.state.eventsRow} titles={["Events & Transactions"]}/>
                        <SimpleTable style={{marginTop: '10px'}} rows={this.state.referralsRow} titles={["Referrals"]}/>

                            <div style={{paddingBottom: '100px', paddingTop: '50px'}}>
                                <Doughnut
                                    data={this.state.professionsForChart}
                                    // width={"10px"}
                                    height={300}
                                    // options={{ maintainAspectRatio: false }}
                                />
                            </div>

                        </div>
                    }


                </div>
            </Container>


        )
    }

    handleDashboardResponse(data) {
        let arr = [];
        arr.push(data);
        let bigQuery = arr[0].bigQuery;
        let bulkPurchases = arr[0].bulkPurchases;
        let users = arr[0].users;
        let eventsAudit = arr[0].eventsAudit;
        let transactions = arr[0].transactions;
        let merchants = arr[0].merchants;
        let referrals = arr[0].referrals;
        let professions = users.professions;
        let sms = arr[0].sms;

        let healthRow = [];
        let usersRow = [];
        //let featuresRow = [];
        let eventsRow = [];
        let referralsRow = [];
        let revenueRow = [];
        let funnelRowIL = [];
        let funnelRowForeign = [];

        // this.setState({payings: {list:bigQuery.payingUsersIds, keyName: "total paying" } });
        // this.setState({loyals: {list:bigQuery.loyalUsersList, keyName: "total loyals" } });
        // this.setState({newLoyals: {list:bigQuery.newLoyalUsersList, keyName: "new loyals" }});


        let totalPayingCount = bigQuery.payingUsersCount.IL + bigQuery.payingUsersCount.US;
        let prvLoyalAbsoluteRatio = (bigQuery.loyalAbsolute / bigQuery.prvLoyalAbsolute * 100).toFixed(1);
        let prvPayingAbsoluteRatio = (totalPayingCount / bigQuery.totalPayingUsersCount30DaysBack * 100).toFixed(1);
        let prvForeignLoyalAbsoluteRatio = (bigQuery.foreignLoyalUsersCount / bigQuery.prvForeignLoyalUsersCount * 100).toFixed(1);
        let prvForeignPayingAbsoluteRatio = (bigQuery.payingUsersCount.US / bigQuery.totalForeignPayingUsersCount30DaysBack * 100).toFixed(1);
        let last30DaysConvertionRate = {
            Foreign: (bigQuery.monthlyConvertedUsers.Foreign / getArrayLenByTz(bigQuery.monthlyRegisteredUsers, "Foreign") * 100).toFixed(2),
            IL: (bigQuery.monthlyConvertedUsers.IL / getArrayLenByTz(bigQuery.monthlyRegisteredUsers, "Asia/Jerusalem") * 100).toFixed(2)
        }
        let last30DaysLoayalRate = {
            Foreign: (getArrayLenByTz(bigQuery.newLoyalUsersList, "Foreign")/ getArrayLenByTz(bigQuery.monthlyRegisteredUsers, "Foreign") * 100).toFixed(2),
            IL: (getArrayLenByTz(bigQuery.newLoyalUsersList, "Asia/Jerusalem")/ getArrayLenByTz(bigQuery.monthlyRegisteredUsers, "Asia/Jerusalem") * 100).toFixed(2)
        }
        let last30DaysPayingRate = {
            Foreign: ((bigQuery.lastMonthForeignPayingUsersCount) / getArrayLenByTz(bigQuery.monthlyRegisteredUsers, "Foreign") * 100).toFixed(2),
            IL: ((bigQuery.lastMonthPayingUsersCount - bigQuery.lastMonthForeignPayingUsersCount) / getArrayLenByTz(bigQuery.monthlyRegisteredUsers, "Asia/Jerusalem") * 100).toFixed(2)
        }
        let prevLast30DaysConvertionRate = {
            Foreign: (bigQuery.prevMonthConvertedUsers.Foreign / getArrayLenByTz(bigQuery.prevMonthRegisteredUsers, "Foreign") * 100).toFixed(2),
            IL: (bigQuery.prevMonthConvertedUsers.IL / getArrayLenByTz(bigQuery.prevMonthRegisteredUsers, "Asia/Jerusalem") * 100).toFixed(2)
        }
        let prvConvertionRatio = {
            Foreign: (last30DaysConvertionRate.Foreign / prevLast30DaysConvertionRate.Foreign * 100).toFixed(1),
            IL: (last30DaysConvertionRate.IL / prevLast30DaysConvertionRate.IL * 100).toFixed(1)
        }
        let prevLast30DaysLoyalRate ={
            Foreign: (getArrayLenByTz(bigQuery.previousLast30DaysLoyalUsers, "Foreign") / getArrayLenByTz(bigQuery.prevMonthRegisteredUsers, "Foreign") * 100).toFixed(2),
            IL: (getArrayLenByTz(bigQuery.previousLast30DaysLoyalUsers, "Asia/Jerusalem") / getArrayLenByTz(bigQuery.prevMonthRegisteredUsers, "Asia/Jerusalem") * 100).toFixed(2)
        }
        let prevLast30PayingRate = {
            Foreign: ((bigQuery.previousLast30DaysForeignPayingUsersCount) / getArrayLenByTz(bigQuery.prevMonthRegisteredUsers, "Foreign") * 100).toFixed(2),
            IL: ((bigQuery.previousLast30DaysPayingUsersCount - bigQuery.previousLast30DaysForeignPayingUsersCount) / getArrayLenByTz(bigQuery.prevMonthRegisteredUsers, "Asia/Jerusalem") * 100).toFixed(2)
        }
        let prvLoyalRatio = {
            Foreign: (last30DaysLoayalRate.Foreign / prevLast30DaysLoyalRate.Foreign * 100).toFixed(1),
            IL: (last30DaysLoayalRate.IL / prevLast30DaysLoyalRate.IL * 100).toFixed(1)
        }
        let prvPayingRatio = {
            Foreign: (last30DaysPayingRate.Foreign / prevLast30PayingRate.Foreign * 100).toFixed(1),
            IL: (last30DaysPayingRate.IL / prevLast30PayingRate.IL * 100).toFixed(1)
        }
        let prvChurnedRatio = (bigQuery.last30DaysChurnedUsersWithRenews / bigQuery.previousLast30DaysChurnedUsersCount * 100).toFixed(1);
        let prvForeignChurnedRatio = (bigQuery.last30DaysChurnedForeignUsersWithRenews / bigQuery.previousLast30DaysChurnedForeignUsersCount * 100).toFixed(1);
        let prvRegisterRatio = {
            Foreign: (bigQuery.monthlyRegisteredUsers.filter(x => x.timeZone === "Foreign").length / bigQuery.prevMonthRegisteredUsers.filter(x => x.timeZone === "Foreign").length * 100).toFixed(1),
            IL: (bigQuery.monthlyRegisteredUsers.filter(x => x.timeZone === "Asia/Jerusalem").length / bigQuery.prevMonthRegisteredUsers.filter(x => x.timeZone === "Asia/Jerusalem").length * 100).toFixed(1)
        }

        usersRow.push({"name": "Registered users (Foreign / Total)", "val": `(${(users.totalForeignUsers / 1000).toFixed(2) + "K"} / ${(users.totalUsers / 1000).toFixed(2) + "K"}) - ${(users.totalForeignUsers / users.totalUsers * 100).toFixed(1)}%`});
        usersRow.push({"name": "Registered customers (Foreign / Total)", "val": `(${(users.foreignNumOfCustomers / 1000).toFixed(2) + "K"} / ${(users.numOfCustomers / 1000).toFixed(2) + "K"}) - ${(users.foreignNumOfCustomers / users.numOfCustomers * 100).toFixed(1)}%`});
        usersRow.push({"name": "DAU (Foreign / Total)", "val": `(${(users.foreignDailyActiveUsers)} / ${(users.dailyActiveUsers)}) - ${(users.foreignDailyActiveUsers / users.dailyActiveUsers * 100).toFixed(1)}%`});
        usersRow.push({"name": "Total Loyal users", "val": bigQuery.loyalAbsolute + " " + getPrvRateString(prvLoyalAbsoluteRatio)});
        usersRow.push({"name": "Foreign Loyal users", "val": bigQuery.foreignLoyalUsersCount + " " + getPrvRateString(prvForeignLoyalAbsoluteRatio)});
        usersRow.push({"name": "Total Paying users", "val": totalPayingCount + " " + getPrvRateString(prvPayingAbsoluteRatio)});
        usersRow.push({"name": "Foreign Paying users", "val": bigQuery.payingUsersCount.US + " " + getPrvRateString(prvForeignPayingAbsoluteRatio)});
        let activeTotalMerchantsRatio = `${(merchants.activeTilledAccounts/merchants.totalTilledAccounts * 100).toFixed(1)}%`
        let enabledActiveMerchantRatio = `${(merchants.paymentsEnabledTilledAccounts/merchants.activeTilledAccounts * 100).toFixed(1)}%`
        //usersRow.push({"name": "Foreign merchants Total / Active / Enabled", "val": `${merchants.totalTilledAccounts} / ${merchants.activeTilledAccounts}(${activeTotalMerchantsRatio}) / ${merchants.paymentsEnabledTilledAccounts}(${enabledActiveMerchantRatio})`});

        usersRow.push({"name": "Avg Paying user monthly value (IL / Foreign)", "val": `${(bigQuery.avgPayingUserValueILS / 3.22).toFixed(2)}$ / ${bigQuery.avgPayingUserValueUSD.toFixed(2)}$`});
        usersRow.push({"name": "Total monthly churned users", "val": bigQuery.last30DaysChurnedUsersCount + getPrvRateString(prvChurnedRatio) + " churn rate : " + ((bigQuery.last30DaysChurnedUsersCount / (totalPayingCount + bigQuery.last30DaysChurnedUsersCount)) *100).toFixed(2) + "%"});
        usersRow.push({"name": "Foreign monthly churned users", "val": bigQuery.last30DaysForeignChurnedUsersCount + getPrvRateString(prvForeignChurnedRatio) + " churn rate : " + ((bigQuery.last30DaysForeignChurnedUsersCount / (bigQuery.payingUsersCount.US + bigQuery.last30DaysForeignChurnedUsersCount)) *100).toFixed(2) + "%"});
        funnelRowIL.push({"name": "registrations",
                          "val": getFunnelBreakString(bigQuery.monthlyRegisteredUsers, "Asia/Jerusalem") + " - 100% " + getPrvRateString(prvRegisterRatio.IL)});
        funnelRowIL.push({"name": "conversions",
                          "val": bigQuery.monthlyConvertedUsers.IL + " - " + last30DaysConvertionRate.IL + "% " + getPrvRateString(prvConvertionRatio.IL)});
        funnelRowIL.push({"name": "loyals",
                          "val": getFunnelBreakString(bigQuery.newLoyalUsersList, "Asia/Jerusalem") +  " - " + last30DaysLoayalRate.IL + "% " +  getPrvRateString(prvLoyalRatio.IL)});
        funnelRowIL.push({"name": "paying",
                          "val": `${bigQuery.lastMonthPayingUsersCount - bigQuery.lastMonthForeignPayingUsersCount} - ` + last30DaysPayingRate.IL + "% " + getPrvRateString(prvPayingRatio.IL)});
        funnelRowForeign.push({"name": "registrations",
                               "val": getFunnelBreakString(bigQuery.monthlyRegisteredUsers, "Foreign") + " - 100% " + getPrvRateString(prvRegisterRatio.Foreign)});
        funnelRowForeign.push({"name": "conversions",
                               "val": bigQuery.monthlyConvertedUsers.Foreign + " - " + last30DaysConvertionRate.Foreign + "% " + getPrvRateString(prvConvertionRatio.Foreign)});
        funnelRowForeign.push({"name": "loyals",
                               "val": getFunnelBreakString(bigQuery.newLoyalUsersList, "Foreign") +  " - " + last30DaysLoayalRate.Foreign + "% " +  getPrvRateString(prvLoyalRatio.Foreign)});
        funnelRowForeign.push({"name": "paying",
                                "val": `${bigQuery.lastMonthForeignPayingUsersCount} - ` + last30DaysPayingRate.Foreign + "% " + getPrvRateString(prvPayingRatio.Foreign)});

        healthRow.push({"name": "Total ", "val": sms.smsTodaySentCounter});
        healthRow.push({"name": "Reminders", "val": sms.smsRemindersTodaySent});
        healthRow.push({"name": "Approvals", "val": sms.smsApprovedSentToday});
        healthRow.push({"name": "Declined,Rejected", "val": sms.smsDeclinedSentToday});
        healthRow.push({"name": "2FA", "val": sms.smsConfirmationSentToday});
        healthRow.push({"name": "Automations - wl/ao/vdb/cns", "val": `${sms.smsWlSentToday}/${sms.smsAoSentToday}/${sms.smsVdbSentToday}/${sms.smsCnsSentToday}`});
        healthRow.push({"name": "Payments - auto refund/payment request", "val": `${sms.smsAutoRefundSentToday}/${sms.smsUpcomingEventWithPayment}`});
        healthRow.push({"name": "Bulk", "val": `${sms.smsBulkSentToday} (${sms.bulkSendTodayCounter})`});

        //featuresRow.push({"name": "External Login", "val": users.externalLoginPercent.toFixed(2)+ "%"});

        let prvAuditRatio = (eventsAudit.lastMonthAuditEvents / eventsAudit.previousLast30DaysAuditEvents * 100).toFixed(1);
        let prvTranVolRatio = {
            ils: (transactions.ils.last30DaysTransVolume / transactions.ils.prevLast30DaysTransVolume * 100).toFixed(1),
            usd: (transactions.usd.last30DaysTransVolume / transactions.usd.prevLast30DaysTransVolume * 100).toFixed(1),
        }
        let prvTranCountRatio = {
            ils: (transactions.ils.last30DaysTransCount / transactions.ils.prevLast30DaysTransCount * 100).toFixed(1),
            usd: (transactions.usd.last30DaysTransCount / transactions.usd.prevLast30DaysTransCount * 100).toFixed(1),
        }
        let prvRevRatio = {
            ils: (transactions.ils.last30DaysRevenue / transactions.ils.prevLast30DaysRevenue * 100).toFixed(1),
            usd: (transactions.usd.last30DaysRevenue / transactions.usd.prevLast30DaysRevenue * 100).toFixed(1)
        }
        let prvRefRegRatio = (referrals.last30DaysReg / referrals.prvLast30DaysReg * 100).toFixed(1);
        let prvRefPayingRatio = (referrals.last30DaysPaying / referrals.prvLast30DaysPaying * 100).toFixed(1);
        eventsRow.push({"name": "Last 30 days events audited", "val": (eventsAudit.lastMonthAuditEvents / 1000).toFixed(2) + "K" + getPrvRateString(prvAuditRatio)});
        //eventsRow.push({"name": "Last 30 days Tran Vol USD", "val": `$${transactions.usd.last30DaysTransVolume} ${getPrvRateString(prvTranVolRatio.usd)}`});
        //eventsRow.push({"name": "Last 30 days Tran Count USD", "val": `${transactions.usd.last30DaysTransCount} ${getPrvRateString(prvTranCountRatio.usd)}`});
        //eventsRow.push({"name": "Last 30 days Tran Revenue USD", "val": `$${transactions.usd.last30DaysRevenue} ${getPrvRateString(prvRevRatio.usd)}`});
        // eventsRow.push({"name": "Last 30 days Tran Vol ILS", "val": `₪${transactions.ils.last30DaysTransVolume} ${getPrvRateString(prvTranVolRatio.ils)}`});
        // eventsRow.push({"name": "Last 30 days Tran Count ILS", "val": `${transactions.ils.last30DaysTransCount} ${getPrvRateString(prvTranCountRatio.ils)}`});
        // eventsRow.push({"name": "Last 30 days Tran Revenue ILS", "val": `₪${transactions.ils.last30DaysRevenue} ${getPrvRateString(prvRevRatio.ils)}`});
        referralsRow.push({"name": "Last 30 days Registrations", "val": (referrals.last30DaysReg) + getPrvRateString(prvRefRegRatio)});
        referralsRow.push({"name": "Last 30 days Paying", "val": (referrals.last30DaysPaying) + getPrvRateString(prvRefPayingRatio)});
        let foreignArrRun = parseInt(((bigQuery.payingUsersCount.US * bigQuery.avgPayingUserValueUSD) * 12).toFixed(0));
        let ILArrRunInDollars = parseInt((((bigQuery.payingUsersCount.IL * bigQuery.avgPayingUserValueILS) / 3.4) * 12).toFixed(0));
        let totalArrRunInDollars = foreignArrRun + ILArrRunInDollars;
        let foreignByrRun = (parseInt(bulkPurchases.last30DaysBulkRev.USD) * 12).toFixed(0);
        let ILByrRun = (parseInt(bulkPurchases.last30DaysBulkRev.ILS) * 12 / 3.4).toFixed(0);
        let totalByrRun = parseInt(foreignByrRun) + parseInt(ILByrRun)
        let foreignTyrRun = transactions.usd.last30DaysRevenue * 12
        let ILTyrRun = transactions.ils.last30DaysRevenue * 12 / 3.4
        let totalTyrRun = foreignTyrRun + ILTyrRun
        revenueRow.push({"name": "$ARR run rate (Foreign / Total)", "val": `(${(foreignArrRun / 1000).toFixed(2) + "K"} / ${(totalArrRunInDollars / 1000).toFixed(2) + "K"}) - ${((foreignArrRun / totalArrRunInDollars) * 100).toFixed(1)}%`});
        //revenueRow.push({"name": "$Annual Tran run rate (Foreign / Total)", "val": `(${(foreignTyrRun / 1000).toFixed(2) + "K"} / ${(totalTyrRun / 1000).toFixed(2) + "K"}) - ${((foreignTyrRun / totalTyrRun) * 100).toFixed(1)}%`})
        //revenueRow.push({"name": "$Annual Bulk run rate (Foreign / Total)", "val": `(${(foreignByrRun / 1000).toFixed(2) + "K"} / ${(totalByrRun / 1000).toFixed(2) + "K"}) - ${((foreignByrRun / totalByrRun) * 100).toFixed(1)}%`})

        let professionsForChart = {
            labels: [],
            datasets: [{
                data: [],
                backgroundColor: [],
                hoverBackgroundColor: []
            }]
        };

        professions.forEach(function (profession) {

            if (profession.professionVariants[0] !== "אחר" && profession.count > 0) {
                professionsForChart.labels.push(profession.professionVariants[0]);
                professionsForChart.datasets[0].data.push(profession.count);
                var rgb = [];
                for (var i = 0; i < 3; i++) {
                    rgb.push(Math.floor(Math.random() * 255));
                }
                let chosenColor = 'rgb(' + rgb.join(',') + ')';
                professionsForChart.datasets[0].backgroundColor.push(chosenColor);
                professionsForChart.datasets[0].hoverBackgroundColor.push(chosenColor);
            }

        });

        this.setState({professionsForChart: professionsForChart});
        this.setState({dataItem: arr});
        this.setState({healthRow: healthRow});
        this.setState({usersRow: usersRow});
        //this.setState({featuresRow: featuresRow});
        this.setState({eventsRow: eventsRow});
        this.setState({referralsRow: referralsRow});
        this.setState({revenueRow: revenueRow});
        this.setState({funnelRowIL: funnelRowIL});
        this.setState({funnelRowForeign: funnelRowForeign});
    }


    componentDidMount() {
        fetch(`${Network.getInstance().getBaseUrl()}system`, {
            method: "GET",
            headers: Network.getInstance().getHeaders()

        })
            .then(function (response) {
                if (Network.getInstance().isTokenExpiredOrInvalid(response.status)) {
                    Network.getInstance().removeAuthentication();
                    return null;
                }
                return response.json();
            })
            .then(function (data) {
                try {
                    this.handleDashboardResponse(data);

                } catch (e) {
                    console.log(e)
                }
            }.bind(this))
            .catch(console.log);
    }
}


export default Dashboard
