import React, {Component} from 'react';

import SimpleCard from './card';
import ButtonAppBar from '../appBar';
import CircularProgress from '@material-ui/core/CircularProgress';
import SimpleTable from '../dashboard/dashboardTable';
import momentTz from 'moment-timezone';
import Button from '@material-ui/core/Button';
import Network from '../../network/network';
import {Link} from 'react-router-dom';
import UserApi from '../../network/userApi';
import UserUtils from '../../utils/userUtils';
import ModalsManager from '../modal/modalsManager';
import userEvent from '@testing-library/user-event';

const loggedInUser = JSON.parse(localStorage.getItem('user'));

const CANCELLATION_REASON = {
    1: {
        name: 'Missing feature',
        sub: [
            {value: 10, label: 'Client payments'},
            {value: 11, label: 'WA'},
            {value: 12, label: 'General'},
            {value: 13, label: 'Multiple/Groups'},
        ],
    }
    ,
    2: {
        name: 'Bugs',
        sub: [
            {value: 20, label: 'CW'},
            {value: 21, label: 'Mobile'},
            {value: 22, label: 'WA'},
        ],
    },
    3: {
        name: 'Pricing',
        sub: [
            {value: 30, label: 'Too expensive'},
            {value: 31, label: 'Changing plan'},
            {value: 32, label: 'Stations upgrade'},
        ],
    },
    4: {
        name: 'Personal',
        sub: [
            {value: 40, label: 'Permanent closed'},
            {value: 41, label: 'Temp close'},
            {value: 42, label: 'Do not need the solution anymore'},
        ],
    },
    5: {
        name: 'General',
        sub: [
            {value: 50, label: 'Bad user experience'},
            {value: 51, label: 'Bad customers experience'},
            {value: 52, label: 'Overall bad experience (user+customer)'},
        ],
    },
    6: {
        name: 'Other',
        sub: [
            {value: 60, label: 'Free text'},
            {value: 61, label: `Didn't provide a reason`},
            {value: 62, label: `Migrated to Nayax`},
        ],
    },
    7: {
        name: 'Paypal',
        sub: [
            {value: 70, label: 'User cancel - no reason'},
        ],
    },
};

function sendWhatsApp(phone) {
    if (!!phone) {
        window.open('https://web.whatsapp.com/send?phone=972' + phone.substring(1, phone.length), '_blank');
    } else {
        window.open('https://web.whatsapp.com/send?phone=972', '_blank');
    }
}

function copyUserInfo(that) {
    var userData = that.state.userData.user;
    console.log(that.state.userId);

    var res = `UID: ${that.state.userId} \n`;

    res += `Email: ${userData.email}\n`;
    res += `Name: ${userData.webLinkId}\n`;

    if (!!userData.phone) {
        res += `Phone: ${userData.phone}\n`;
    }

    res += `TimeZone: ${userData.timeZone}\n`;

    res += `LT: ${userData.userLifeTimeInDays}\n`;

    res += `Plan: ${userData.pricingPlanId}\n`;

    res += 'Conversation link:\n[insert here]\n';

    res += 'Description:\n[insert here]';

    navigator.clipboard.writeText(res);
//
}

function copyUserEmail(that){
    const userEmail = that.state.userData.user.email
    navigator.clipboard.writeText(userEmail);
}
function updateUserClientLogsStatus() {
    UserApi.getInstance().updateClientLogs(this.state.userId, !this.state.userData.user.clientLogsEnabled)
        .then(function(data) {
            try {
                let userData = this.state.userData;
                userData.user.clientLogsEnabled = !this.state.userData.user.clientLogsEnabled;
                this.handleUserResponse(userData, this.state.userId);
            } catch (e) {
                console.log(e);
            }
        }.bind(this))
        .catch(console.log);
}

function resetPassword() {
    UserApi.getInstance().resetPassword(this.state.userId)
        .then((data) => {
            try {
                let userData = this.state.userData;
                userData.user.hasTempPassword = data.hasTempPassword;
                this.handleUserResponse(userData, this.state.userId);

            } catch (e) {
                console.log(e);
            }
        });
}

function blockUser() {
    UserApi.getInstance().blockUser(this.state.userId, !this.state.userData.user.isBlocked)
        .then((data) => {
            try {
                let userData = this.state.userData;
                userData.user.isBlocked = data.isBlocked;
                this.handleUserResponse(userData, this.state.userId);

            } catch (e) {
                console.log(e);
            }
        });
}

function revertPassword() {
    UserApi.getInstance().revertPassword(this.state.userId)
        .then((data) => {
            try {
                let userData = this.state.userData;
                userData.user.hasTempPassword = data.hasTempPassword;
                this.handleUserResponse(userData, this.state.userId);

            } catch (e) {
                console.log(e);
            }
        });
}

function deleteUer() {
    let userNameToDelete = prompt('Enter the User name of the user you would like to delete');
    if (userNameToDelete.trim() === this.state.userData.user.userName.trim()) {
        console.log('Deleting user');
        UserApi.getInstance().deleteUser(this.state.userId)
            .then((response) => {
                try {
                    if (response.status === 200) {
                        this.showDeleteUserSuccessAlert();
                    } else {
                        this.showDeleteUserFailAlert();
                    }
                } catch (e) {
                    this.showDeleteUserFailAlert();
                    console.log(e);
                }
            });
    } else {
        alert('Entered user name doesn\'t match');
    }

}

function freezeUserAccount() {
    let userNameToFreeze = prompt('Enter the User name of the user you would like to freeze his account');
    if (userNameToFreeze.trim() === this.state.userData.user.userName.trim()) {

        console.log('Freezing user account');
        UserApi.getInstance().freezeUserAccount(this.state.userId)
            .then((response) => {
                try {
                    if (response.status === 200) {
                        let userData = this.state.userData;
                        userData.user.isCwBlocked = true;
                        this.handleUserResponse(userData, this.state.userId);
                        alert('user account freezed successfully');
                    } else {
                        alert('fail to freeze user account');
                    }
                } catch (e) {
                    alert('fail to freeze user account');
                    console.log(e);
                }
            });
    } else {
        alert('Entered user name doesn\'t match');
    }
}

function unfreezeUserAccount() {
    console.log('unFreezing user account');
    UserApi.getInstance().unFreezeUserAccount(this.state.userId)
        .then((response) => {
            try {
                if (response.status === 200) {
                    let userData = this.state.userData;
                    userData.user.isCwBlocked = false;
                    this.handleUserResponse(userData, this.state.userId);
                    alert('user account freezed successfully');
                } else {
                    alert('fail to freeze user account');
                }
            } catch (e) {
                alert('fail to freeze user account');
                console.log(e);
            }
        });
}


class User extends Component {

    state = {
        privileges: null,
        userData: null,
        internalToken: null,
        monitorUserId: null,
        usersRow: [],
        userName: [],
        webLinkId: [],
        userId: '',
        servicesRow: [],
        nylasRow: [],
        brandingRow: [],
        windowsRow: [],
        eventsRow: [],
        paymentsRow: [],
        subscriptionsRow: [],
        stationsRow: [],
        showManualSubscriptionModal: false,
        showManualSubscriptionSuccessAlertModal: false,
        showManualSubscriptionFailedAlertModal: false,

        showDeleteUserSuccessAlertModal: false,
        showDeleteUserFailedAlertModal: false,

        modalPricingPlanId: '',
        modalSubscriptionPercentDiscount: '',
        modalSubscriptionDiscount: '',
        modalStationsToAdd: '',
        manualSubscriptionAmount: '',
        manualSubscriptionMethod: '',
        manualSubscriptionEndDate: '',
        manualSubscriptionPhone: null,
        manualSubscriptionUsername: null,
        modalError: null,

        showGeneratePaymentLinkModal: false,

        showCancelSubscriptionModal: false,
        showCancelSubscriptionSuccessAlertModal: false,
        showCancelSubscriptionFailedAlertModal: false,
        mainCancellationReason: '',
        subCancellationReason: '',
        cancellationFreeText: '',
        cancellationEndDate: '',
        subCancellationMenuItems: null,
        shouldShowFreeText: true,

        showSubscriptionCouponModal: false,
        subscriptionCouponCode: '',
    };

    componentDidMount() {

        let userId = new URLSearchParams(this.props.location.search).get('q');
        if (userId === null) {
            // Extract the userId from the query parameter in the URL
            let splittedUrl = window.location.href.split('/');
            userId = splittedUrl[splittedUrl.length - 1];
        }

        let privileges = new URLSearchParams(this.props.location.search).get('privileges');
        this.privileges = privileges;
        this.setState({privileges: privileges});
        this.setState({internalToken: loggedInUser.internalToken});
        this.setState({monitorUserId: loggedInUser.userId});

        console.log('monitorUserId', loggedInUser.userId);
        console.log('internalToken', loggedInUser.internalToken);

        fetch(`${Network.getInstance().getBaseUrl()}users/${userId}`, {
            method: 'GET',
            headers: Network.getInstance().getHeaders(),
        })
            .then(function(response) {
                if (Network.getInstance().isTokenExpiredOrInvalid(response.status)) {
                    Network.getInstance().removeAuthentication();

                    return null;
                }
                return response.json();
            })
            .then(function(data) {
                try {
                    this.handleUserResponse(data, userId);

                } catch (e) {
                    console.log(e);
                }
            }.bind(this))
            .catch(console.log);

    }

    render() {
        return (
            <div>
                <div style={{marginTop: '30%', position: 'fixed', width: '100%', textAlign: 'center'}}>
                    {this.state.usersRow.length === 0 ? <CircularProgress/> : null}
                </div>
                <div>
                    {/*<SimpleCard contacts={this.state.usersToShow}/>*/}
                    {

                        <Button variant="contained" color="primary"
                                onClick={() => copyUserInfo(this)}>Ticket template</Button>

                    }
                    {

                        <Button variant="contained" color="primary"
                                onClick={() => copyUserEmail(this)}>Copy Email</Button>

                    }
                    {
                        this.isAdmin()
                            ?
                            <Button variant="contained" color="primary"
                                    onClick={() => sendWhatsApp(this.state.phone)}>send
                                WhatsApp</Button>
                            :
                            null
                    }
                    {
                        this.state.userData ?
                            <a style={{textDecoration: 'none'}}
                               href={this.getCWUrl()} target="_blank" rel="noopener noreferrer">
                                <Button variant="contained" color="primary">CW</Button>
                            </a>
                            : null
                    }

                    <a style={{textDecoration: 'none'}}
                       href={`https://restricted.plannieapp.com/#!/?userid=${this.state.userId}&plannieToken=f3c1ee35-1132-4b4f-ba34-b818e63dac1e&monitorLoginToken=${this.state.internalToken}&monitorUserId=${this.state.monitorUserId}`}
                       target="_blank" rel="noopener noreferrer">
                        <Button variant="contained" color="primary">Restricted App</Button>
                    </a>


                    <a style={{textDecoration: 'none'}}
                       href={this.hasEmail() ? `https://web.plannieapp.com/#!/?email=${this.state.userData.user.email}` : `https://web.plannieapp.com/#!/`}
                       target="_blank" rel="noopener noreferrer">
                        <Button variant="contained" color="primary">MW - Production</Button>
                    </a>


                    {
                        this.hasPaymentLink()
                            ?
                            <a style={{textDecoration: 'none'}}
                               href={`${this.state.userData.user.paymentLink}&debug=true#!/`}>
                                <Button variant="contained" color="primary">BP</Button>
                            </a>
                            :
                            null
                    }

                    {
                        this.state.userData && this.isAdmin()
                            ?
                            this.getClientLogsButton()
                            :
                            null
                    }
                    {
                        this.state.userData && this.isAdmin()
                            ?
                            this.getResetPassButton()
                            :
                            null
                    }

                    <SimpleTable style={{marginTop: '10px'}} rows={this.state.usersRow} titles={this.state.userName}/>
                    {
                        this.state.userData && (this.getStationsString().includes('Master'))
                            ?
                            <SimpleTable style={{marginTop: '10px'}} rows={this.state.stationsRow}
                                         titles={['Stations']}/>
                            :
                            null
                    }
                    {
                        this.state.userData && (this.getStationsString().includes('Master') || this.getStationsString() === 'Single Station')
                            ?
                            <SimpleTable style={{marginTop: '10px'}} rows={this.state.subscriptionsRow}
                                         titles={['Subscriptions']}/>
                            :
                            null
                    }
                    {
                        this.state.userData && this.state.userData.user.payments && (this.getStationsString().includes('Master') || this.getStationsString() === 'Single Station')
                            ?
                            <SimpleTable style={{marginTop: '10px'}} rows={this.state.paymentsRow}
                                         titles={['Payments']}/>
                            :
                            null
                    }
                    <SimpleTable style={{marginTop: '10px'}} rows={this.state.eventsRow}
                                 titles={[`Events ${this.state.userData && this.getStationsString().includes('Master') ? '(All stations)' : ''}`]}/>
                    {/*<SimpleTable style={{marginTop: '10px'}} rows={this.state.brandingRow} titles={["Branding"]}/>*/}
                    {
                        this.state.userData && this.state.userData.user.nylas && this.state.userData.user.nylas.length > 0
                            ?
                            <SimpleTable style={{marginTop: '10px'}} rows={this.state.nylasRow} titles={['Nylas']}/>
                            :
                            null
                    }
                    {
                        this.state.userData && (this.getStationsString().includes('Master') || this.getStationsString() === 'Single Station')
                            ?
                            <SimpleTable style={{marginTop: '10px'}} rows={this.state.servicesRow}
                                         titles={['Services']}/>
                            :
                            null
                    }
                    {
                        this.state.userData && (this.getStationsString().includes('Master') || this.getStationsString() === 'Single Station')
                            ?
                            <SimpleTable style={{marginTop: '10px'}} rows={this.state.windowsRow} titles={['Windows']}/>
                            :
                            null
                    }

                    <div style={{paddingBottom: '25px', paddingTop: '50px'}}></div>

                </div>

                <div>
                    {this.state.showManualSubscriptionModal ?
                        ModalsManager.getInstance().getManualSubscriptionModal(
                            this.onManualSubscriptionSaveClicked.bind(this),
                            this.onManualSubscriptionCloseClicked.bind(this),
                            this.state.modalPricingPlanId,
                            this.onModalPricingPlanIdChange.bind(this),
                            this.onModalStationsToAddChange.bind(this),
                            this.onModalSubscriptionDiscountChange.bind(this),
                            this.onModalSubscriptionPercentDiscountChange.bind(this),
                            this.onManualSubscriptionAmountChange.bind(this),
                            this.state.manualSubscriptionMethod,
                            this.onManualSubscriptionMethodChange.bind(this),
                            this.onManualSubscriptionEndDateChange.bind(this),
                            this.onManualSubscriptionPhoneChange.bind(this),
                            this.onManualSubscriptionUsernameChange.bind(this),
                            this.state.modalError)
                        :
                        null}
                </div>

                <div>
                    {this.state.showGeneratePaymentLinkModal ?
                        ModalsManager.getInstance().getGeneratePaymentLinkModal(
                            this.onGeneratePaymentLinkClicked.bind(this),
                            this.onGeneratePaymentLinkCloseClicked.bind(this),
                            this.state.modalPricingPlanId,
                            this.onModalPricingPlanIdChange.bind(this),
                            this.onModalStationsToAddChange.bind(this),
                            this.onModalSubscriptionPercentDiscountChange.bind(this),
                            this.onModalSubscriptionDiscountChange.bind(this),
                            this.state.modalError)
                        :
                        null}
                </div>

                <div>
                    {this.state.showManualSubscriptionSuccessAlertModal ?
                        ModalsManager.getInstance().getAlertModal(
                            'החיוב בוצע בהצלחה',
                            'יש לשלוח קבלה ללקוח במקרה שהתשלום בוצע במזומן/צ\'ק/העברה',
                            'info',
                            this.onManualSubscriptionSuccessAlertCloseClicked.bind(this),
                            this.onManualSubscriptionSuccessAlertCloseClicked.bind(this),
                        )
                        :
                        null}
                </div>

                <div>
                    {this.state.showManualSubscriptionFailedAlertModal ?
                        ModalsManager.getInstance().getAlertModal(
                            'החיוב נכשל',
                            'יש לנסות בשנית או לפנות למנהל המערכת',
                            'error',
                            this.onManualSubscriptionFailAlertCloseClicked.bind(this),
                            this.onManualSubscriptionFailAlertCloseClicked.bind(this),
                        )
                        :
                        null}
                </div>

                <div>
                    {this.state.showDeleteUserSuccessAlertModal ?
                        ModalsManager.getInstance().getAlertModal(
                            'המחיקה בוצעה בהצלחה',
                            `המשתמש ${this.state.userData.user.userName} נמחק בהצלחה מהמערכת `,
                            'success',
                            this.onDeleteUserSuccessAlertOkClicked.bind(this),
                            this.onDeleteUserSuccessAlertCloseClicked.bind(this),
                        )
                        :
                        null}
                </div>

                <div>
                    {this.state.showManualSubscriptionFailedAlertModal ?
                        ModalsManager.getInstance().getAlertModal(
                            'המחיקה נכשלה',
                            `מחיקת המשתמש ${this.state.userData.user.userName} נכשלה. יש לפנות למנהל המערכת `,
                            'error',
                            this.onDeleteUserFailAlertCloseClicked.bind(this),
                            this.onDeleteUserFailAlertCloseClicked.bind(this),
                        )
                        :
                        null}
                </div>

                <div>
                    {this.state.showCancelSubscriptionModal ?
                        ModalsManager.getInstance().getCancelSubscriptionModal(
                            this.onCancelSubscriptionSaveClicked.bind(this),
                            this.onCancelSubscriptionCloseClicked.bind(this),
                            this.state.mainCancellationReason,
                            this.onMainCancellationReasonChanged.bind(this),
                            this.state.subCancellationReason,
                            this.onSubCancellationReasonChange.bind(this),
                            this.onCancellationFreeTextChange.bind(this),
                            this.onCancellationEndDateChange.bind(this),
                            this.state.subCancellationMenuItems,
                            this.state.shouldShowFreeText,
                            this.state.modalError)
                        :
                        null}
                </div>

                <div>
                    {this.state.showSubscriptionCouponModal ?
                        ModalsManager.getInstance().getSubscriptionCouponModal(
                            this.onAddCouponClicked.bind(this),
                            this.onSubscriptionCouponCodeClosedClicked.bind(this),
                            this.state.subscriptionCouponCode,
                            this.onSubscriptionCouponCodeChanged.bind(this))
                        :
                        null}
                </div>

                {
                    this.state.userData && this.isAdmin()
                        ?
                        this.getBlockButton()
                        :
                        null
                }

                {
                    this.state.userData && this.isAdmin()
                        ?
                        <Button style={{backgroundColor: '#ec0101'}} variant="contained"
                                onClick={deleteUer.bind(this)}>DELETE USER
                        </Button>
                        :
                        null
                }

                {
                    this.state.userData && (this.isAdmin() || this.isSupport())
                        ?
                        this.getFreezeButton()
                        :
                        null
                }

            </div>

        );
    }

    isAdmin() {
        return this.state.privileges === 'admin';
    }

    isSupport() {
        return this.state.privileges === 'support';
    }

    hasPaymentLink() {
        return this.state.userData && !!this.state.userData.user.paymentLink;
    }

    hasEmail() {
        return this.state.userData && !!this.state.userData.user && !!this.state.userData.user.email;
    }

    getCWUrl() {
        let qaParams = this.state.userData.user.webLinkId ?
            `?name=${this.state.userData.user.webLinkId}` : `?userid=${this.state.userId}`;
        let prodParams = `?name=${this.state.webLinkId}&debug=true`;

        return Network.getInstance().isLocalHost()
            ? `${Network.getInstance().getCWBaseUrl()}${qaParams}` : `${Network.getInstance().getCWBaseUrl()}${prodParams}`;
    }

    getFreezeButton() {
        return this.isFreezeEnabled() ?

            <Button style={{backgroundColor: '#ec0101'}} variant="contained"
                    onClick={freezeUserAccount.bind(this)}>FREEZE USER ACCOUNT
            </Button>
            :
            <Button style={{backgroundColor: '#63ec01'}} variant="contained"
                    onClick={unfreezeUserAccount.bind(this)}>UNFREEZE USER ACCOUNT
            </Button>;
    }

    getClientLogsButton() {
        return this.isClientLogsEnabled()
            ?
            <Button variant="contained" color="primary"
                    onClick={updateUserClientLogsStatus.bind(this)}>Disable Client
                Logs</Button>
            :
            <Button variant="contained" color="primary"
                    onClick={updateUserClientLogsStatus.bind(this)}>Enable Client
                Logs</Button>;
    }

    getResetPassButton() {
        return this.state.userData.user.hasTempPassword
            ?
            <Button style={{backgroundColor: '#28df99'}} variant="contained"
                    onClick={revertPassword.bind(this)}>Revert password
            </Button>
            :
            <Button style={{backgroundColor: '#ec0101'}} variant="contained"
                    onClick={resetPassword.bind(this)}>Reset password
            </Button>;
    }

    getBlockButton() {
        return this.state.userData.user.isBlocked
            ?
            <Button style={{backgroundColor: '#28df99'}} variant="contained"
                    onClick={blockUser.bind(this)}>Unblock
            </Button>
            :
            <Button style={{backgroundColor: '#e85d04'}} variant="contained"
                    onClick={blockUser.bind(this)}>Block
            </Button>;
    }

    getIgnoreBillingMismatchObject() {
        return {
            'ignoreStations': false,
            'ignoreServices': false,
        };
    }

    initModalState() {
        this.setState({
            modalPricingPlanId: '',
            modalPricingPlanCoupon: '',
            modalStationsToAdd: '',
            modalError: null,
        });
    }


    handleUserResponse(data, userId) {
        this.setState({
            userData: data,
            userName: [data.user.userName + ' - ' + this.getStationsString(data.user) + ' (' + data.user.profession + ') ' + `(${data.user.userLifeTimeInDays} Days)` + this.getFreezeState(data.user)],
        });

        console.log(data);
        let usersRow = [];
        let subscriptionsRow = [];
        let paymentsRow = [];
        let stationsRow = [];
        let eventsRow = [];
        let servicesRow = [];
        let windowsRow = [];
        let nylasRow = [];
        let brandingRow = [];
        document.title = `${data.user.userName}`;

        if (data.user.isBlocked) {
            usersRow.push({
                'actionType': 'alert',
                'actionName': 'USER IS BLOCKED',
            });
        }

        function getStationState(stationState) {
            return stationState === 0 ? 'inactive' : 'active';
        }

        function getPairingState(stationState) {
            switch (stationState) {
                case 0:
                    return 'unpaired';
                case 1:
                    return 'pending';
                case 2:
                    return 'paired';
                default:
                    return '';
            }

        }

        function getCurrentPrivileges(currentUrl) {
            let url = new URL(currentUrl);
            return !!url.searchParams.get('privileges') ? url.searchParams.get('privileges') : 'viewer';
        }

        if (data.user.stationsData && data.user.stationsData.length > 0) {
            for (let i = 0; i < data.user.stationsData.length; i++) {
                stationsRow.push({
                    'name': `Station - ${i + 1}`,
                    'actionType': 'button',
                    'actionName': 'Go To Station',
                    'action': this.onStationClicked.bind(this),
                    'data': `${window.location.origin.toString()}/main/user/?q=${data.user.stationsData[i].userId}&privileges=${this.privileges}`,
                    'val': `${data.user.stationsData[i].userId} - ${data.user.stationsData[i].stationName} (${getStationState(data.user.stationsData[i].stationState)}, ${getPairingState(
                        data.user.stationsData[i].pairingState)})`,
                });
            }
        }

        let phone = !!data.user.phone && data.user.phone.length > 0 ? data.user.phone : 'no phone';
        if (!!data.user.masterUserId) {
            usersRow.push({
                'name': `Master User`,
                'actionType': 'button',
                'actionName': 'Go To Master',
                'action': this.onStationClicked.bind(this),
                'data': `${window.location.origin.toString()}/main/user/?q=${data.user.masterUserId}&privileges=${this.privileges}`,
                'val': `${data.user.masterUserId}`,
            });
        }

        usersRow.push({
            'name': 'version ; device',
            'val': data.user.clientType + '( ' + data.user.appVersion + ' ) ; ' + data.user.device,
        });
        usersRow.push({
            'name': 'userId ; webLinkId ; email ; phone',
            'val': `${userId} ; ${data.user.webLinkId} ; ${data.user.email} ; ${phone}`,
        });
        if (!!data.user.recordedPhones && data.user.recordedPhones.length > 0) {
            usersRow.push({
                'name': 'recordedPhones',
                'val': `${data.user.recordedPhones.toString()}`,
            });
        }

        if (this.getLoginType(data.user) !== 'Internal Login') {
            usersRow.push({
                'name': `loginType ; lastLogin`,
                'actionType': 'button',
                'actionName': 'Set As Internal Login',
                'action': this.setAsInternalLogin.bind(this),
                'data': `${this.state.userId}`,
                'val': `${this.getLoginType(data.user)} ; ${momentTz(data.user.lastLoginDate).tz('Asia/Jerusalem').format('DD/MM/YY HH:mm')}`,
            });
        } else {
            usersRow.push({
                'name': 'loginType ; lastLogin',
                'val': `${this.getLoginType(data.user)} ; ${momentTz(data.user.lastLoginDate).tz('Asia/Jerusalem').format('DD/MM/YY HH:mm')}`,
            });
        }

        usersRow.push({
            'name': 'timeZone ; systemLanguage ; currency ; servicesCurrency',
            'val': `${data.user.timeZone} ; ${data.user.systemLanguage} ; ${data.user.currency} ; ${data.user.servicesCurrency}`,
        });
        usersRow.push({
            'name': 'smsEnabled ; ReminderEnabled',
            'val': `${data.user.smsEnabled === true ? 'true' : 'false'} ; ${data.user.eventsReminderEnabled === true ? 'true' : 'false'}`,
        });
        usersRow.push({
            'name': 'customers ; feedViews ; smsCounter',
            'val': `${data.user.customers} ; ${data.user.feedViews} ; ${data.user.smsCounter}`,
        });

        let isTrialEnded = data.user.userLifeTimeInDays > data.user.trialPeriodInDays;
        subscriptionsRow.push({
            'name': 'trial period in days ; is trial ended',
            'val': `${data.user.trialPeriodInDays} ; ${isTrialEnded ? 'true' : 'false'}`,
        });
        if (data.user.extensionsClaimed && data.user.extensionsClaimed.length > 0) {
            subscriptionsRow.push({
                'name': 'extensionsClaimed Date ; freePassUntil Date',
                'val': `${momentTz(data.user.extensionsClaimed[0]).tz('Asia/Jerusalem').format('DD/MM/YY')} ; ${momentTz(data.user.freePassUntil).tz('Asia/Jerusalem').format('DD/MM/YY')}`,
            });
        }

        let slotConventionVal = data.user.slotConvention || this.getUserSlotConvention(data.user);
        let slotConventionActionButtonText = slotConventionVal === 'v1' ? 'SET v2' : 'SET v1';
        usersRow.push({
            'name': 'slotConvention',
            'val': `${slotConventionVal}`,
            'actionType': 'button',
            'actionName': slotConventionActionButtonText,
            'action': this.updateSlotConvention.bind(this),
            'data': slotConventionActionButtonText,
        });

        // email update
        subscriptionsRow.push({
            'name': 'Update Email',
            'val': data.user.email,
            'actionType': 'button',
            'actionName': 'Update Email',
            'action': (this.isAdmin() || this.isSupport()) ? this.onUpdateEmailClicked.bind(this) : null,
            'data': data.user,
        });
        let ignoreOTPVal = data.user.ignoreOTP;
        let ignoreOTPButtonText = ignoreOTPVal === true ? 'REMOVE IGNORE' : 'IGNORE';

        usersRow.push({
            'name': 'ignoreOTP',
            'val': `${ignoreOTPVal.toString()}`,
            'actionType': 'button',
            'actionName': ignoreOTPButtonText,
            'action': this.updateIgnoreOTP.bind(this),
            'data': ignoreOTPButtonText,
        });

        // forceNylasExport

        usersRow.push({
            'name': 'forceNylasExport',
            'val': `${data.user.forceNylasExport?.toString()}`,
            'actionType': 'button',
            'actionName': 'TOGGLE STATE',
            'action': () => this.updateUser(toggleFlag('forceNylasExport', data.user.forceNylasExport)),
            'data': data.user.forceNylasExport,
        });


        let allowHundredServicesVal = data.user.allowHundredServices;
        let allowHundredServicesButtonText = 'TOGGLE STATE';

        function toggleFlag(key, value) {
            console.log(key, value, 'toggleflag');
            return {key: key, value: !value};
        }

        usersRow.push({
            'name': 'allowHundredServices',
            'val': `${allowHundredServicesVal?.toString()}`,
            'actionType': 'button',
            'actionName': allowHundredServicesButtonText,
            'action': () => this.updateUser(toggleFlag('allowHundredServices', allowHundredServicesVal)),
        });

        //showStationsFirstVal
        let showStationsFirstVal = data.user.showStationsFirst;
        let showStationsFirstButtonText = 'TOGGLE STATE';

        usersRow.push({
            'name': 'showStationsFirst',
            'val': `${showStationsFirstVal?.toString()}`,
            'actionType': 'button',
            'actionName': showStationsFirstButtonText,
            'action': this.updateShowStationsFirst.bind(this),
            'data': showStationsFirstVal,
        });

        //isEcomEligible
        let isEcomEligibleVal = data.user.isEcomEligible;
        let isEcomEligibleButtonText = 'TOGGLE STATE';

        usersRow.push({
            'name': 'isEcomEligible',
            'val': `${isEcomEligibleVal?.toString()}`,
            'actionType': 'button',
            'actionName': isEcomEligibleButtonText,
            'action': this.updateIsEcomEligible.bind(this),
            'data': isEcomEligibleVal,
        });

        // ispunchcardsEnabled
        let isPunchcardsEnabledVal = data.user.isPunchcardsEnabled;
        let isPunchcardsEnabledButtonText = 'TOGGLE STATE';

        usersRow.push({
            'name': 'isPunchcardsEnabled',
            'val': `${isPunchcardsEnabledVal?.toString()}`,
            'actionType': 'button',
            'actionName': isPunchcardsEnabledButtonText,
            'action': this.updateIsPunchcardsEnabled.bind(this),
            'data': isPunchcardsEnabledVal,
        });

        // isMoveEnabled
        let isMoveEnabledVal = data.user.isMoveEnabled;
        let isMoveEnabledButtonText = 'TOGGLE STATE';

        usersRow.push({
            'name': 'isMoveEnabled',
            'val': `${isMoveEnabledVal?.toString()}`,
            'actionType': 'button',
            'actionName': isMoveEnabledButtonText,
            'action': () => this.updateUser(toggleFlag('isMoveEnabled', isMoveEnabledVal)),
            'data': isMoveEnabledVal,
        });

        // showPaidIndicatorOnSlot
        let showPaidIndicatorOnSlotVal = data.user.showPaidIndicatorOnSlot;
        let showPaidIndicatorOnSlotButtonText = 'TOGGLE STATE';

        usersRow.push({
            'name': 'showPaidIndicatorOnSlot',
            'val': `${showPaidIndicatorOnSlotVal?.toString()}`,
            'actionType': 'button',
            'actionName': showPaidIndicatorOnSlotButtonText,
            'action': () => this.updateUser(toggleFlag('showPaidIndicatorOnSlot', showPaidIndicatorOnSlotVal)),
            'data': showPaidIndicatorOnSlotVal,
        });

        //forceBookInstantlyFromOpenSlot
        let forceBookInstantlyFromOpenSlotVal = data.user.forceBookInstantlyFromOpenSlot;
        let forceBookInstantlyFromOpenSlotButtonText = 'TOGGLE STATE';

        usersRow.push({
            'name': 'forceBookInstantlyFromOpenSlot',
            'val': `${forceBookInstantlyFromOpenSlotVal?.toString()}`,
            'actionType': 'button',
            'actionName': forceBookInstantlyFromOpenSlotButtonText,
            'action': () => this.updateUser(toggleFlag('forceBookInstantlyFromOpenSlot', forceBookInstantlyFromOpenSlotVal))
        });
        //isServiceDurationHiddenFromCW
        let isServiceDurationHiddenFromCWVal = data.user.isServiceDurationHiddenFromCW;
        let isServiceDurationHiddenFromCWButtonText = 'TOGGLE STATE';


        usersRow.push({
            'name': 'isServiceDurationHiddenFromCW',
            'val': `${isServiceDurationHiddenFromCWVal?.toString()}`,
            'actionType': 'button',
            'actionName': isServiceDurationHiddenFromCWButtonText,
            'action': () => this.updateUser(toggleFlag('isServiceDurationHiddenFromCW', isServiceDurationHiddenFromCWVal))
        });

        //removeCustomerFromMessages
        let customerUnsubscribeButtonText = 'Customer Phone number';
        let customerUnsubscribeFlags = {
            marketingUnsubscribed : true,
            bulkUnsubscribed : true,
            optOutEmailUpdates : true
        }
        usersRow.push({
            'name': 'clientUnsubscribe',
            'actionType': 'input',
            'actionName': customerUnsubscribeButtonText,
            'data': {
                'customerPhone': '',
            },
            'action': (customerPhone) => {this.updateCustomer(customerPhone,customerUnsubscribeFlags)},
        });


        if (!!data.user.subscriptionCouponCode) {
            subscriptionsRow.push({
                'name': 'Subscription coupon code',
                'val': data.user.subscriptionCouponCode,
                'actionType': 'button',
                'actionName': 'Change',
                'action': this.onSubscriptionCouponCodeClicked.bind(this),
                'data': data.user.subscriptionCouponCode,
            });
        } else {
            subscriptionsRow.push({
                'name': 'Subscription coupon code',
                'actionType': 'button',
                'actionName': 'Add coupon',
                'action': this.onSubscriptionCouponCodeClicked.bind(this),
                'data': data.user.subscriptionCouponCode,
            });
        }

        subscriptionsRow.push({
            'name': 'available bulk tokens',
            'val': `${data.user.availableBulkTokens || 0}`,
            'actionType': 'button',
            'actionName': ['Add 1', 'Add 3'],
            'action': this.addBulkClicked.bind(this),
            'data': data.user.paymentLink,
        });

        if (!!data.user.paymentLink) {
            subscriptionsRow.push({
                'name': 'paymentLink',
                'val': data.user.paymentLink,
                'actionType': 'button',
                'actionName': 'Copy to clipboard',
                'action': this.onPaymentLinkCopied,
                'data': data.user.paymentLink,
            });
            if (!!data.user.paymentLinkTemp) {
                subscriptionsRow.push({
                    'name': 'paymentLinkTemp',
                    'val': data.user.paymentLinkTemp,
                    'actionType': 'button',
                    'actionName': 'Copy to clipboard',
                    'action': this.onPaymentLinkCopied,
                    'data': data.user.paymentLinkTemp,
                });
            } else {
                subscriptionsRow.push({
                    'name': 'paymentLinkTemp',
                    'actionType': 'button',
                    'actionName': 'Generate',
                    'action': this.onGeneratePaymentLink.bind(this),
                    'data': data.user.paymentLinkTemp,
                });
            }
        } else {
            subscriptionsRow.push({
                'name': 'paymentLink',
                'actionType': 'button',
                'actionName': 'Generate',
                'action': this.onGeneratePaymentLink.bind(this),
                'data': data.user.paymentLink,
            });
        }

        if (this.isPaying(data.user)) {
            if (data.user.currentSubscription.subscriptionCancelled) {
                console.log('FREE TEXT: ', data.user.currentSubscription);
                let mainCancellationReason = !!data.user.currentSubscription.cancelReasonEnum ? CANCELLATION_REASON[data.user.currentSubscription.cancelReasonEnum.main] : null;
                let subCancellationReason = !!data.user.currentSubscription.cancelReasonEnum.sub ? mainCancellationReason.sub.find(
                    subReason => subReason.value === data.user.currentSubscription.cancelReasonEnum.sub) : null;
                subscriptionsRow.push(this.getManualSubscriptionButton(data.user));
                subscriptionsRow.push({
                    'name': 'subscriptionPaymentId',
                    'val': data.user.subscriptionPaymentId,
                    'actionType': 'alert',
                    'actionName': 'Subscription Cancelled!',
                });
                if (!!mainCancellationReason) {
                    subscriptionsRow.push({
                        'name': 'cancelReason',
                        'val': `${mainCancellationReason.name} | 
                    ${data.user.currentSubscription.cancelReasonEnum.freeText ? data.user.currentSubscription.cancelReasonEnum.freeText : subCancellationReason?.label}`
                            ||
                            '(No Cancel Reason)',
                    });
                } else {
                    subscriptionsRow.push({
                        'name': 'cancelReason',
                        'val': `${!!data.user.currentSubscription.cancelReason ? data.user.currentSubscription.cancelReason : '(No Cancel Reason)'}`,
                    });
                }
            } else {
                let paidStationsStr = data.user.currentPaidStations > 0 ? ` - ${data.user.currentPaidStations} Additional Paid Stations` : '';
                subscriptionsRow.push({
                    'name': 'Subscription',
                    'val': data.user.pricingPlanId > 0
                        ?
                        `${this.getPlanName(
                            data.user.pricingPlanId)}${paidStationsStr} ; ${data.user.currentSubscription.subscriptionType} ; ${data.user.currentSubscription.subscriptionAmount} ${data.user.currency} ; ${data.user.currentSubscription.method} ; ${data.user.currentSubscription.email} ; ${data.user.currentSubscription.userName}`
                        :
                        'No Active Subscription',
                });
                subscriptionsRow.push({
                    'name': 'subscriptionPaymentId', 'val': data.user.subscriptionPaymentId,
                    'actionType': 'button',
                    'actionName': 'Cancel Subscription',
                    'action': this.isAdmin() ? this.onCancelSubscription.bind(this) : null,
                    'data': data.user,
                });
            }
            subscriptionsRow.push({
                'name': 'subscriptionStartDate ; subscriptionEndDate',
                'val': `${!!data.user.currentSubscription.startDate ?
                    momentTz(data.user.currentSubscription.startDate).tz('Asia/Jerusalem').format('DD/MM/YY')
                    :
                    ''} ; ${!!data.user.currentSubscription.subscriptionEndDate ?
                    momentTz(data.user.currentSubscription.subscriptionEndDate).tz('Asia/Jerusalem').format('DD/MM/YY')
                    :
                    'No end date'}`,
            });
            subscriptionsRow.push({
                'name': 'latestApprovedPaymentDate ; nextPaymentDate',
                'val': `${!!data.user.currentSubscription.latestApprovedPaymentDate ?
                    momentTz(data.user.currentSubscription.latestApprovedPaymentDate).tz('Asia/Jerusalem').format('DD/MM/YY')
                    :
                    ''} ; ${!!data.user.currentSubscription.nextPaymentDate ?
                    momentTz(data.user.currentSubscription.nextPaymentDate).tz('Asia/Jerusalem').format('DD/MM/YY')
                    :
                    'No next payment date'}`,
            });

        } else {
            subscriptionsRow.push(this.getManualSubscriptionButton(data.user));
        }

        if (!!data.subscriptions && data.subscriptions.aggregatedActivityDays > 0 && data.subscriptions.aggregatedPaidAmount > 0) {
            subscriptionsRow.push({
                'name': 'aggregatedPaidDays ; aggregatedPaidAmount',
                'val': `${data.subscriptions.aggregatedActivityDays} ; ${data.subscriptions.aggregatedPaidAmount}`,
            });
        }
        if (this.isAdmin()) {
            subscriptionsRow.push({
                'name': 'freePassUntil',
                'val': momentTz(data.user.freePassUntil).tz('Asia/Jerusalem').format('DD/MM/YY'),
                'actionType': 'datePicker',
                'action': this.handleDateChangedChange,
                'data': data.user,
            });
        } else {
            subscriptionsRow.push({
                'name': 'freePassUntil',
                'val': !!data.user.freePassUntil ? momentTz(data.user.freePassUntil).tz('Asia/Jerusalem').format('DD/MM/YY') : '',
            });
        }

        subscriptionsRow.push({
            'name': 'Note',
            'val': data.user.paymentNote,
            'actionType': 'button',
            'actionName': 'Add Note',
            'action': (this.isAdmin() || this.isSupport()) ? this.onNoteClicked.bind(this) : null,
            'data': data.user,
        });

        let currentStationsOnSubscription = parseInt(data.user.currentSubscription.stationsToAdd) || 0;
        if (!data.user.currentSubscription.subscriptionCancelled && data.user.currentPaidStations !== currentStationsOnSubscription) {
            subscriptionsRow.push({
                'name': 'Has stations mismatch',
                'val': `assigned on user - ${data.user.currentPaidStations} :  paid on subscription - ${data.user.currentSubscription.stationsToAdd}`,
            });
        }

        subscriptionsRow.push({
            'name': 'Ignore billing mismatch',
            'actionType': 'checkbox',
            'data': !!data.user.ignoreBillingMismatch ? data.user.ignoreBillingMismatch : this.getIgnoreBillingMismatchObject(),
            'action': this.isAdmin() ? this.onIgnoreBillingMismatchClicked.bind(this) : null,
        });

        eventsRow.push({
            'name': 'manual future approved ; total future approved',
            'val': data.userEvents.futureManualApproved + ' ; ' + data.userEvents.futureApproved +
                ` (${(data.userEvents.futureManualApproved / data.userEvents.futureApproved * 100).toFixed(2) + '%'})`,
        });
        eventsRow.push({
            'name': 'total manual approved ; total approved',
            'val': data.userEvents.manualApproved + ' ; ' + data.userEvents.totalApproved + ` (${(data.userEvents.manualApproved / data.userEvents.totalApproved * 100).toFixed(2) + '%'})`,
        });
        if (data.user.isWlEnabled) {
            eventsRow.push({
                'name': 'future wl approved ; total future approved',
                'val': data.userEvents.futureWlApproved + ' ; ' + data.userEvents.futureApproved + ` (${(data.userEvents.futureWlApproved / data.userEvents.futureApproved * 100).toFixed(2) + '%'})`,
            });
            eventsRow.push({
                'name': 'events wl last month ; events last month',
                'val': data.userEvents.eventsWlLastMonth + ' ; ' + data.userEvents.eventsLastMonth +
                    ` (${(data.userEvents.eventsWlLastMonth / data.userEvents.eventsLastMonth * 100).toFixed(2) + '%'})`,
            });
            eventsRow.push({
                'name': 'latest approved in days ; monthly avg income',
                'val': data.userEvents.latestApprovedEventInDays + ' ; ' + '$' + Math.round(data.userEvents.monthlyIncome),
            });
        } else {
            eventsRow.push({
                'name': 'events last month ; latest approved in days ; monthly avg income',
                'val': data.userEvents.eventsLastMonth + ' ; ' + data.userEvents.latestApprovedEventInDays + ' ; ' + '$' + Math.round(data.userEvents.monthlyIncome),
            });
        }

        for (var i = 1; i <= data.user.services.length; i++) {
            servicesRow.push({
                'name': 'service - ' + i,
                'val': data.user.services[i - 1].name + ' ' + data.user.services[i - 1].durationInMinutes + ' דקות ',
            });
        }
        for (var i = 1; i <= data.userWindows.length; i++) {
            windowsRow.push({
                'name': 'window - ' + i,
                'val': data.userWindows[i - 1].windowName + '\n ' + data.userWindows[i - 1].durationInMinutes + ' דקות \n' + data.userWindows[i - 1].activeServices.length + ' סרוויסים פעילים',
            });
        }
        if (!!data.user.nylas) {
            for (var i = 1; i <= data.user.nylas.length; i++) {
                nylasRow.push({
                    'name': 'Account: ' + data.user.nylas[i - 1].email,
                    'actionType': 'button',
                    'actionName': 'Go To Account',
                    'action': this.onNylasClicked.bind(this),
                    'data': `https://dashboard.nylas.com/applications/nfwrlmorh7yuxgh5cngcdg8e/accounts/${data.user.nylas[i - 1].accountId}`,
                });
                nylasRow.push({
                    'name': 'isActive - import/export',
                    'val': `${!!data.user.nylas[i - 1].accessToken ? 'true' : 'false'} - ${!!data.user.nylas[i - 1].isImportCalendarEnabled + '/' + !!data.user.nylas[i - 1].isExportCalendarEnabled}`,
                });
            }
        }

        if (!!data.user.payments) {

            paymentsRow.push({
                'name': 'provider',
                'val': data.user.payments.providerId === 1 ? 'Tilled' : 'Hyp',
            });
            paymentsRow.push({
                'name': 'merchant account id',
                'val': data.user.merchantAccount.merchantAccountId,
            });
            paymentsRow.push({
                'name': 'merchant account status',
                'val': data.user.merchantAccount.status === 3 ? 'active' :
                    data.user.merchantAccount.status === 2 ? 'submitted' :
                        data.user.merchantAccount.status === 1 ? 'started' :
                            data.user.merchantAccount.status === 0 ? 'created' :
                                data.user.merchantAccount.status === 6 ? 'disabled' : 'unknown',
            });
            paymentsRow.push({
                'name': 'is enabled',
                'val': data.user.payments.isEnabled ? 'True' : 'False',
            });
            paymentsRow.push({
                'name': 'deposit percentage',
                'val': data.user.payments.depositPct ? data.user.payments.depositPct : 0,
            });
            //isYaadWithEasycount
            let isYaadWithEasycountVal = data.user.merchantAccount.isYaadWithEasycount;
            let isYaadWithEasycountButtonText = 'TOGGLE STATE';

            paymentsRow.push({
                'name': 'isYaadWithEasycount',
                'val': `${isYaadWithEasycountVal?.toString()}`,
                'actionType': 'button',
                'actionName': isYaadWithEasycountButtonText,
                'action': () => this.updateMerchant(toggleFlag('isYaadWithEasycount', isYaadWithEasycountVal), data.user.merchantAccount._id),
            });

            //preventTokenPayment
            let preventTokenPaymentVal = data.user.payments.preventTokenPayment
            let preventTokenPaymentButtonText = 'TOGGLE STATE'

            paymentsRow.push({
                'name': 'preventTokenPayment',
                'val': `${preventTokenPaymentVal?.toString()}`,
                'actionType': 'button',
                'actionName': preventTokenPaymentButtonText,
                'action': () => this.updateUser(toggleFlag('payments.preventTokenPayment', preventTokenPaymentVal))
            });

            //Delete Merchant
            let deleteMerchantButtonText = 'Delete';

            if(UserUtils.getInstance().isLocalHost()){
                paymentsRow.push({
                    'name': 'deleteMerchant',
                    'actionType': 'button',
                    'actionName': deleteMerchantButtonText,
                    'action': () => window.confirm("Are you sure you want to delete this merchant?") ? this.deleteMerchant(data.user.merchantAccount._id) : null
                })
            }

            //useOnlyInvoices
            let useOnlyInvoicesVal = data.user.payments.useOnlyInvoices;
            let useOnlyInvoicesButtonText = 'TOGGLE STATE';

            if (!!data.user.payments) {
                paymentsRow.push({
                    'name': 'useOnlyInvoices',
                    'val': `${useOnlyInvoicesVal?.toString()}`,
                    'actionType': 'button',
                    'actionName': useOnlyInvoicesButtonText,
                    'action': () => this.updateUser(toggleFlag('payments.useOnlyInvoices', data.user.payments.useOnlyInvoices)),
                    'data': useOnlyInvoicesVal,
                });
            }



        }
        // brandingRow.push({
        //     "name": "branding color",
        //     "val": !!data.user.branding && !!data.user.branding.mainColor ?
        //         data.user.branding.mainColor :
        //         "default"
        // });
        // brandingRow.push({
        //     "name": "carouselImages",
        //     "val": !!data.user.branding && data.user.branding.carouselImages.length > 0
        //         ? data.user.branding.carouselImages.length
        //         : "no carouselImages"
        // });
        // brandingRow.push({
        //     "name": "services images",
        //     "val": !!data.user.services
        //         ? data.user.services.filter(x => !!x.image).length
        //         : 0
        // });
        // brandingRow.push({
        //     "name": "profile image & status",
        //     "val": `image:${!!data.user.imageUrl}. status:${!!data.user.aboutMe}`
        // });
        // brandingRow.push({
        //     "name": "links",
        //     "val": `facebook:${!!data.user.fbLink}. instagram:${!!data.user.instagramLink}. phone:${!!data.user.phone}. navigation:${!!data.user.navigationAddress && !!data.user.navigationAddress.text}`
        // });

        this.setState({usersRow: usersRow});
        this.setState({subscriptionsRow: subscriptionsRow});
        this.setState({paymentsRow: paymentsRow});
        this.setState({stationsRow: stationsRow});
        this.setState({eventsRow: eventsRow});
        this.setState({servicesRow: servicesRow});
        this.setState({windowsRow: windowsRow});
        this.setState({nylasRow: nylasRow});
        this.setState({brandingRow: brandingRow});
        this.setState({webLinkId: data.user.webLinkId});
        this.setState({userId: userId});

        if (!!data.user.phone && data.user.phone.length > 0) {
            this.setState({phone: data.user.phone});
        }
    }

    getUserSlotConvention(user) {
        if (!!user.createdAppVersion) {
            console.log('user.createdAppVersion: ' + user.createdAppVersion);
            return parseInt(user.createdAppVersion.split('.')[0]) >= 7 ? 'v2' : 'v1';
        }

        return 'v1';
    }

    getPlanName(planId) {
        if (planId == 1) return 'Plus';
        if (planId == 11) return 'Entry';
        if (planId == 2) return 'Team';
        if (planId == 14) return 'Premium';
        if (planId == 20) return 'Premium Payments';
        return '';
    }

    getFreezeState(user) {
        if (!user) {
            user = this.state.userData.user;
        }
        if (user.isCwBlocked) {
            return '- Freezed';
        }

        return '';
    }

    isClientLogsEnabled() {
        let user = !!this.state.userData ? this.state.userData.user : null;
        return !!user && user.clientLogsEnabled;
    }

    isFreezeEnabled() {
        let user = !!this.state.userData ? this.state.userData.user : null;
        return !!user && !user.isFreezed;
    }

    getLoginType(user) {
        if (!!user.hasPassword) {
            return 'Internal Login';
        }

        return user.externalProvider;
    }

    getStationsString(user) {
        if (!user) {
            user = this.state.userData.user;
        }
        if (!!user.childUserIds && user.childUserIds.length > 0) {
            return `Master with ${user.childUserIds.length} additional stations`;
        }
        if (!!user.masterUserId) {
            let pairingState = user.pairingState === 1 || user.pairingState === 2 ? 'paired' : 'unpaired';
            let stationState = user.stationState === 0 ? 'inactive' : 'active';
            return `${stationState} ${pairingState} child`;
        }

        return 'Single Station';
    }

    isEligibleForEntryPlan(data) {
        return this.isPaying(data.user) &&
            data.userEvents.eventsLastMonth <= 50 &&
            (typeof data.user.services === 'undefined' ||
                (!!data.user.services && data.user.services.length <= 6)) &&

            // not a child
            typeof data.user.masterUserId === 'undefined' &&

            // not master or master with no children
            (typeof data.user.childUserIds === 'undefined' ||
                (!!data.user.childUserIds && data.user.childUserIds.length === 0));
    }

    getManualSubscriptionButton(user) {
        return {
            'name': 'Subscription',
            'val': this.isPaying(user)
                ? `${this.getPlanName(
                    user.pricingPlanId)} ; ${user.currentSubscription.subscriptionType} ; ${user.currentSubscription.subscriptionAmount} ${user.currency} ; ${user.currentSubscription.method}`
                : 'No Active Subscription',
            'actionType': 'button',
            'actionName': 'Manual Subscription',
            'action': this.isAdmin() ? this.onManualSubscription.bind(this) : null,
            'data': user,
        };
    }

    addBulkClicked(type, data) {
        let tokenToAdd = type === 'Add 1' ? 1 : 3;
        UserApi.getInstance().addBulkTokens(this.state.userId, tokenToAdd)
            .then((data) => {
                this.handleAddBulkTokensResponse(data);
            });
    }

    updateSlotConvention(data) {
        let slotConventionToSet = data === 'SET v1' ? 'v1' : 'v2';
        UserApi.getInstance().updateUserSlotConvention(this.state.userId, slotConventionToSet)
            .then((data) => {
                this.handleUpdatedSlotConventionResponse(slotConventionToSet);
            });
    }

    updateIgnoreOTP(data) {
        let slotConventionToSet = data === 'IGNORE';
        UserApi.getInstance().updateUserIgnoreOTP(this.state.userId, slotConventionToSet)
            .then((data) => {
                this.handleUpdatedIgnoreOTPResponse(slotConventionToSet);
            });
    }

    updateUser(payload) {
        UserApi.getInstance().updateUser(this.state.userId, payload)
            .then((res) => {
                this.handleUpdatedUserResponse(res);
            });
    }

    updateMerchant(payload, merchantId) {
        debugger
        console.log('update merchant', payload, merchantId);
        UserApi.getInstance().updateMerchant(this.state.userId, payload, merchantId)
            .then((res) => {
                this.handleUpdatedMerchantResponse(res);
            });
    }

    deleteMerchant(merchantId){
        console.log(`Delete merchant ${merchantId} for user ${this.state.userId}`);
        UserApi.getInstance().deleteMerchant(this.state.userId, merchantId)
            .then((res) => {
                this.handleDeletedMerchantResponse(res);
            });
    }

    updateForceNylasExport(data) {
        let isForceNylasExport = !data;
        UserApi.getInstance().updateForceNylasExport(this.state.userId, isForceNylasExport)
            .then((data) => {
                this.handleForceNylasExportResponse(isForceNylasExport);
            });
    }

    updateShowStationsFirst(data) {
        let shouldSetStationFirst = !data;
        UserApi.getInstance().updateUserShowStationsFirst(this.state.userId, shouldSetStationFirst)
            .then((data) => {
                this.handleUpdatedShowStationsFirstResponse(shouldSetStationFirst);
            });
    }

    updateIsEcomEligible(data) {
        let isEcomEligible = !data;
        UserApi.getInstance().updateUserIsEcomEligible(this.state.userId, isEcomEligible)
            .then((data) => {
                this.handleUpdatedIsEcomEligibleResponse(isEcomEligible);
            });
    }

    updateIsPunchcardsEnabled(data) {
        let isPunchcardsEnabled = !data;

        UserApi.getInstance().updateUserIsPunchcardsEnabled(this.state.userId, isPunchcardsEnabled)
            .then((data) => {
                this.handleUpdatedIsPunchcardsEnabledReponse(isPunchcardsEnabled);
            });
    }

    updateCustomer(customerPhone, payload){
        if(UserUtils.getInstance().isValidPhone(customerPhone)){
            UserApi.getInstance().updateUserCustomer(this.state.userId, customerPhone,payload)
                .then((res) => {
                    this.handleUpdatedCustomerResponse(res);
                });
        }else{
            alert('Not a valid phone number')
        }

    }

    handleAddBulkTokensResponse(data) {
        let userData = this.state.userData;
        userData.user.availableBulkTokens = data.availableBulkTokens;
        this.handleUserResponse(userData, this.state.userId);
    }

    handleUpdatedSlotConventionResponse(slotConventionToSet) {
        let userData = this.state.userData;
        userData.user.slotConvention = slotConventionToSet;
        this.handleUserResponse(userData, this.state.userId);
    }

    handleUpdatedIgnoreOTPResponse(updatedIgnoreOTP) {
        let userData = this.state.userData;
        userData.user.ignoreOTP = updatedIgnoreOTP;
        this.handleUserResponse(userData, this.state.userId);
    }

    //added payments to the update user function
    handleUpdatedUserResponse(res) {
        let errorMessage = '';

        if (res.status === 200) {
            const payload = res.data;
            const userData = this.state.userData;

            // Iterate through the payload object and update userData.user
            for (const key in payload) {
                if (payload.hasOwnProperty(key)) {
                    // Split the key based on the dot (".")
                    const keys = key.split('.');

                    // Check if the first part of the key is payments
                    if (keys[0] === 'payments') {
                        if (!userData.user.payments) {
                            alert(`user ${userData.userId} doesn't have payments`)
                            return
                        }
                        userData.user.payments[keys.slice(1).join('.')] = payload[key];
                    } else {
                        userData.user[key] = payload[key];
                    }
                }
            }

            this.handleUserResponse(userData, this.state.userId);
            return;
        } else if (res.status === 403) {
            errorMessage = res.err;
        } else {
            errorMessage = res.err;
        }

        alert(errorMessage);
    }


    handleUpdatedMerchantResponse(res) {
        let errorMessage = '';
        if (res.status === 200) {
            //Don't sent the userId
            let payload = res.data;
            let userData = this.state.userData;
            for (const key in payload) {
                if (payload.hasOwnProperty(key)) {
                    userData.user.merchantAccount[key] = payload[key];
                }
            }
            this.handleUserResponse(userData, this.state.userId);
            return;
        } else if (res.status === 403) {
            errorMessage = res.err;
        } else {
            errorMessage = res.err;
        }
        alert(errorMessage);
    }
    handleDeletedMerchantResponse(res) {
        let errorMessage = '';
        if (res.status === 200) {
            //Don't sent the userId
            let payload = res.data;
            let userData = this.state.userData;
            for (const key in payload) {
                if (payload.hasOwnProperty(key)) {
                    userData.user.merchantAccount[key] = payload[key];
                }
            }
            this.handleUserResponse(userData, this.state.userId);
            alert('Merchant Deleted!')
            return;
        } else if (res.status === 403) {
            errorMessage = res.err;
        } else {
            errorMessage = res.err;
        }
        alert(errorMessage);
    }
    handleForceNylasExportResponse(newValue) {
        let userData = this.state.userData;
        userData.user.forceNylasExportResponse = newValue;
        this.handleUserResponse(userData, this.state.userId);
    }

    handleUpdatedShowStationsFirstResponse(newValue) {
        let userData = this.state.userData;
        userData.user.showStationsFirst = newValue;
        this.handleUserResponse(userData, this.state.userId);
    }

    handleUpdatedIsEcomEligibleResponse(newValue) {
        let userData = this.state.userData;
        userData.user.isEcomEligible = newValue;
        this.handleUserResponse(userData, this.state.userId);
    }

    handleUpdatedIsPunchcardsEnabledReponse(newValue) {
        let userData = this.state.userData;
        userData.user.isPunchcardsEnabled = newValue;
        this.handleUserResponse(userData, this.state.userId);
    }

    handleUpdatedCustomerResponse(data) {
            const status = data.status
            const error = data.err
        if(status === 404){
            alert(error)
        }else if(status === 400){
            alert(error)
        }else {
           alert("Customer updated successfully")
        }
    }
    isPaying(user) {
        return user.pricingPlanId > 0;
    }

    showManualSubscriptionSuccessAlert() {
        console.log('showManualSubscriptionSuccessAlert');
        this.setState({showManualSubscriptionSuccessAlertModal: true});
    }

    onManualSubscriptionSuccessAlertCloseClicked() {
        console.log('onManualSubscriptionSuccessAlertCloseClicked');
        this.setState({showManualSubscriptionSuccessAlertModal: false});
    }

    showManualSubscriptionFailAlert() {
        console.log('showManualSubscriptionFailAlert');
        this.setState({showManualSubscriptionFailedAlertModal: true});
    }

    onManualSubscriptionFailAlertCloseClicked() {
        console.log('onManualSubscriptionFailAlertCloseClicked');
        this.setState({showManualSubscriptionFailedAlertModal: false});
    }

    onCancelSubscription(user) {
        console.log('onCancelSubscription: ', user);
        this.setState({showCancelSubscriptionModal: true});
    }

    onCancelSubscriptionCloseClicked() {
        this.setState({showCancelSubscriptionModal: false});
    }

    onMainCancellationReasonChanged(selectedOption) {
        let selectedOptionVal = selectedOption.target.value;
        this.setState({mainCancellationReason: selectedOptionVal});
        this.setState({subCancellationMenuItems: CANCELLATION_REASON[selectedOptionVal].sub});
    }

    onSubCancellationReasonChange(selectedOption) {
        let selectedOptionVal = selectedOption.target.value;
        this.setState({subCancellationReason: selectedOptionVal});
    }

    onCancellationFreeTextChange(event) {
        this.setState({cancellationFreeText: event.target.value});
    }

    onCancellationEndDateChange(event) {
        this.setState({cancellationEndDate: event.target.value});
    }

    onCancelSubscriptionSaveClicked() {
        let mainCancellationReason = this.state.mainCancellationReason;
        if (!mainCancellationReason || mainCancellationReason.length === 0) {
            this.setState({modalError: 'Please choose main cancellation reason'});
            return;
        }
        let subCancellationReason = this.state.subCancellationReason;
        if (!subCancellationReason || subCancellationReason.length === 0) {
            this.setState({modalError: 'Please choose sub cancellation reason'});
            return;
        }
        let cancellationFreeText = this.state.cancellationFreeText;
        if (this.state.shouldShowFreeText &&
            this.state.subCancellationReason === CANCELLATION_REASON[6].sub[0].value &&
            (!cancellationFreeText || cancellationFreeText.length === 0)) {
            this.setState({modalError: 'Please add cancellation free text reason'});
            return;
        }

        let cancellationEndDate = this.state.cancellationEndDate;
        let endDateMillis = null;
        if (!!cancellationEndDate) {
            endDateMillis = new Date(cancellationEndDate).getTime();
        }

        this.setState({modalError: null});
        console.log('Form is valid');

        UserApi.getInstance().cancelSubscription(this.state.userId, this.state.userData.user.subscriptionPaymentId, mainCancellationReason, subCancellationReason, cancellationFreeText, endDateMillis)
            .then((response) => {
                if (response.status === 200) {
                    try {
                        response.json().then(data => {
                            this.handleCancelSubscriptionResponse(data);
                            this.setState({showCancelSubscriptionModal: false});
                        });

                    } catch (e) {
                        console.log(e);
                    }
                } else {
                    this.setState({modalError: 'End date is mandatory'});
                }
            });
    }

    /************************************  DELETE USER  ************************************/

    showDeleteUserSuccessAlert() {
        console.log('showDeleteUserSuccessAlert');
        this.setState({showDeleteUserSuccessAlertModal: true});
    }

    onDeleteUserSuccessAlertOkClicked() {
        console.log('onDeleteUserSuccessAlertOkClicked');
        window.close();
    }

    onDeleteUserSuccessAlertCloseClicked() {
        console.log('onDeleteUserSuccessAlertCloseClicked');
        this.setState({showDeleteUserSuccessAlertModal: false});
    }

    showDeleteUserFailAlert() {
        console.log('showDeleteUserFailAlert');
        this.setState({showDeleteUserFailedAlertModal: true});
    }

    onDeleteUserFailAlertCloseClicked() {
        console.log('onDeleteUserFailAlertCloseClicked');
        this.setState({showDeleteUserFailedAlertModal: false});
    }

    onReleaseUserFromBlock(userData) {
        console.log('onReleaseUserFromBlock: ', userData);
        UserApi.getInstance().releaseUser(this.state.userId)
            .then((data) => {
                try {
                    this.handleBlockingResponse(data);
                } catch (e) {
                    console.log(e);
                }
            });
    }

    onNylasClicked(link) {
        console.log('onNylasClicked');
        window.open(link, '_blank');
    }

    onStationClicked(link) {
        console.log('onStationClicked');
        window.open(link, '_blank');
    }

    setAsInternalLogin(userId) {
        UserApi.getInstance().resetPassword(this.state.userId)
            .then((response) => {
                try {
                    if (response.hasTempPassword === true) {
                        alert('user account set as internal login with temp pass: abc123');
                    } else {
                        alert('fail to set as internal login');
                    }
                } catch (e) {
                    alert('fail to set as internal login');
                    console.log(e);
                }
            });
    }

    onUpdateEmailClicked() {
        console.log('onUpdateEmailClicked');
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        let currentEmail = this.state.userData.user.email;
        let newEmail = prompt(`New email is:`, !!currentEmail ? currentEmail : '');
        if (!!newEmail && newEmail !== '') {
            if (!emailRegex.test(newEmail)) {
                alert('Email not written correctly');
                return;
            }
            this.updateEmail(newEmail);
        }
    }

    onNoteClicked() {
        console.log('onNoteClicked');
        let currentNote = this.state.userData.user.paymentNote;
        let note = prompt('Please add your note', !!currentNote ? currentNote : '');
        if (!!note && note !== '') {
            this.updateNote(note);
        } else {
            this.deleteNote();
        }
    }

    onIgnoreBillingMismatchClicked(key, isChecked) {
        console.log(`${key} checked`, isChecked);
        let ignoreBillingMismatchData = !!this.state.userData.user.ignoreBillingMismatch ? this.state.userData.user.ignoreBillingMismatch : this.getIgnoreBillingMismatchObject();
        ignoreBillingMismatchData[key] = isChecked;
        UserApi.getInstance().updateIgnoreBillingMismatch(this.state.userId, ignoreBillingMismatchData)
            .then((data) => {
                this.handleIgnoreBillingMismatchResponse(data);
            });
    }

    //yoni question
    updateEmail(email) {
        UserApi.getInstance().updateEmail(this.state.userId, email)
            .then((res) => {
                this.handleUpdatedUserResponse(res)
            });
    }

    updateNote(note) {
        UserApi.getInstance().updateNote(this.state.userId, note)
            .then((data) => {
                this.handleNoteResponse(data);
            });
    }

    deleteNote() {
        UserApi.getInstance().deleteNote(this.state.userId)
            .then(() => {
                this.handleNoteResponse(null);
            });
    }


    handleNoteResponse(data) {
        let userData = this.state.userData;
        userData.user.paymentNote = !!data ? data.paymentNote : null;
        this.handleUserResponse(userData, this.state.userId);
    }

    handleIgnoreBillingMismatchResponse(data) {
        let userData = this.state.userData;
        userData.user.ignoreBillingMismatch = data.ignoreBillingMismatch;
        this.handleUserResponse(userData, this.state.userId);
    }

    handleBlockingResponse(data) {
        if (this.state.userId === data.userId) {
            let userData = this.state.userData;
            userData.user.blockingType = data.blockingType;
            userData.user.isBillingBlocked = data.isBillingBlocked;
            this.handleUserResponse(userData, this.state.userId);
        }
    }

    onPaymentLinkCopied(paymentLink) {
        console.log('onPaymentLinkCopied: ', paymentLink);
        navigator.clipboard.writeText(paymentLink);
    }

    onGeneratePaymentLink() {
        console.log('onGeneratePaymentLink');
        this.initModalState();
        this.setState({showGeneratePaymentLinkModal: true});
    }

    onGeneratePaymentLinkCloseClicked() {
        console.log('onGeneratePaymentLinkCloseClicked');
        this.setState({showGeneratePaymentLinkModal: false});
    }

    async onGeneratePaymentLinkClicked() {
        console.log('onGeneratePaymentLinkClicked');
        let userData = this.state.userData;
        let pricingPlanId = this.state.modalPricingPlanId;
        if (!pricingPlanId || pricingPlanId.length === 0) {
            this.setState({showGeneratePaymentLinkModal: true});
            this.setState({modalError: 'Please choose a pricing plan id'});
            return;
        }
        let stationsToAdd = this.state.modalStationsToAdd;

        if (!stationsToAdd || stationsToAdd.length === 0) {
            this.setState({showGeneratePaymentLinkModal: true});
            this.setState({modalError: 'Please add the number of stations to add'});
            return;
        }

        if (stationsToAdd > 5 && userData.user.timeZone !== 'Asia/Jerusalem') {
            this.setState({modalError: 'More than 5 stations are not allowed'});
            return;
        }

        this.setState({modalError: null});

        let paymentLink = `https://billing.plannieapp.com/?isBillingMode=true&userId=${this.state.userId}&pricingPlanId=${this.state.modalPricingPlanId}&stationsToAdd=${this.state.modalStationsToAdd}&currency=${this.state.userData.user.currency.toUpperCase()}&lang=${this.state.userData.user.systemLanguage}`;

        if (!!this.state.modalSubscriptionDiscount) {
            let discount = this.state.modalSubscriptionDiscount;
            try {
                const data = await UserApi.getInstance().encryptDiscount(discount);
                paymentLink += !!data.encryptedDiscount ? `&d=${data.encryptedDiscount}` : '' ;
            } catch (e) {
                console.log(e);
            }
        }

        if (!!this.state.modalSubscriptionPercentDiscount) {
            let discount = this.state.modalSubscriptionPercentDiscount;
            try {
                const data = await UserApi.getInstance().encryptDiscount(discount);
                paymentLink += !!data.encryptedDiscount ? `&p=${data.encryptedDiscount}` : '' ;
            } catch (e) {
                console.log(e);
            }
        }

        if (stationsToAdd > 5) {
            paymentLink = paymentLink + '&forceYaadOnly=true';
        }


        this.setState({modalError: null});

        if (!userData.user.paymentLink) {
            userData.user.paymentLink = paymentLink;
        } else {
            userData.user.paymentLinkTemp = paymentLink;
        }

        this.setState({showGeneratePaymentLinkModal: false});
        this.handleUserResponse(userData, this.state.userId);
    }

    onSubscriptionCouponCodeClicked() {
        this.setState({
            subscriptionCouponCode:
                !!this.state.userData.user.subscriptionCouponCode ? this.state.userData.user.subscriptionCouponCode : '',
        });
        this.setState({showSubscriptionCouponModal: true});
    }

    onAddCouponClicked() {
        this.setState({showSubscriptionCouponModal: false});
        let couponCode = this.state.subscriptionCouponCode;
        UserApi.getInstance().setSubscriptionCouponCode(this.state.userId, couponCode)
            .then((data) => {
                this.handleSubscriptionCouponCodeResponse(data);
            });
    }

    onSubscriptionCouponCodeClosedClicked() {
        this.setState({showSubscriptionCouponModal: false});
    }

    onSubscriptionCouponCodeChanged(event) {
        this.setState({subscriptionCouponCode: event.target.value});
    }

    handleSubscriptionCouponCodeResponse(data) {
        let userData = this.state.userData;
        userData.user.subscriptionCouponCode = data.subscriptionCouponCode;
        this.handleUserResponse(userData, this.state.userId);
    }

    handleDateChangedChange = date => {
        console.log('handleDateChangedChange: ', date);
        if (date instanceof Date) {
            let newDate = new Date(date);
            let now = new Date();
            if (newDate > now) {
                this.updateFreePass(newDate.getTime());
            }
        } else {
            this.removeFreePass();
        }
    };

    updateFreePass(freePassTimestamp) {
        UserApi.getInstance().updateFreePass(freePassTimestamp, this.state.userId)
            .then(() => {
                try {
                    let userData = this.state.userData;
                    userData.user.freePassUntil = freePassTimestamp;
                    this.onReleaseUserFromBlock(userData);
                    this.handleUserResponse(userData, this.state.userId);
                } catch (e) {
                    console.log(e);
                }
            });
    }

    removeFreePass() {
        UserApi.getInstance().removeFreePass(this.state.userId)
            .then(() => {
                try {
                    let userData = this.state.userData;
                    userData.user.freePassUntil = null;
                    this.handleUserResponse(userData, this.state.userId);

                } catch (e) {
                    console.log(e);
                }
            });
    }

    handleCancelSubscriptionResponse(data) {
        let userData = this.state.userData;
        userData.user.currentSubscription.subscriptionEndDate = data.subscriptionEndDate;
        userData.user.currentSubscription.subscriptionCancelled = data.subscriptionCancelled;
        userData.user.currentSubscription.cancelReasonEnum = data.cancelReasonEnum;
        this.handleUserResponse(userData, this.state.userId);
    }

    onManualSubscription(user) {
        console.log('onManualSubscription: ', user);
        this.initModalState();
        this.setState({showManualSubscriptionModal: true});
    }

    onManualSubscriptionCloseClicked() {
        this.setState({showManualSubscriptionModal: false});
    }

    onModalPricingPlanIdChange(event) {
        if (event.target.value.toString().includes('&')) {
            let splittedValue = event.target.value.toString().split('&');
            this.setState({modalPricingPlanId: parseInt(splittedValue[0])});
            this.setState({modalPricingPlanCoupon: splittedValue[1]});

        } else {
            this.setState({modalPricingPlanId: event.target.value});
        }
    }

    onModalStationsToAddChange(event) {
        this.setState({modalStationsToAdd: event.target.value});
    }

    onModalSubscriptionDiscountChange(event) {
        this.setState({modalSubscriptionDiscount: event.target.value});
    }

    onModalSubscriptionPercentDiscountChange(event) {
        this.setState({modalSubscriptionPercentDiscount: event.target.value});
    }

    onManualSubscriptionAmountChange(event) {
        this.setState({manualSubscriptionAmount: event.target.value});
    }

    onManualSubscriptionMethodChange(event) {
        this.setState({manualSubscriptionMethod: event.target.value});
    }

    onManualSubscriptionEndDateChange(event) {
        this.setState({manualSubscriptionEndDate: event.target.value});
    }

    onManualSubscriptionPhoneChange(event) {
        this.setState({manualSubscriptionPhone: event.target.value});
    }

    onManualSubscriptionUsernameChange(event) {
        this.setState({manualSubscriptionUsername: event.target.value});
    }

    onManualSubscriptionSaveClicked() {
        let pricingPlanId = this.state.modalPricingPlanId;
        if (!pricingPlanId || pricingPlanId.length === 0) {
            this.setState({modalError: 'Please choose a pricing plan id'});
            return;
        }
        let stationsToAdd = this.state.modalStationsToAdd;
        if (!stationsToAdd || stationsToAdd.length === 0) {
            this.setState({modalError: 'Please add the number of stations to add'});
            return;
        }
        let amount = this.state.manualSubscriptionAmount;
        if (!amount || amount.length === 0) {
            this.setState({modalError: 'Please add an amount'});
            return;
        }
        let method = this.state.manualSubscriptionMethod;
        if (!method || method.length === 0) {
            this.setState({modalError: 'Please choose a method'});
            return;
        }
        let endDate = this.state.manualSubscriptionEndDate;
        if (!endDate || endDate.length === 0) {
            this.setState({modalError: 'Please choose an end date'});
            return;
        }
        let endDateMillis = new Date(endDate).getTime();

        let phone = this.state.manualSubscriptionPhone;
        let username = this.state.userData.user.userName;

        this.setState({modalError: null});
        console.log('Form is valid');

        UserApi.getInstance().manualSubscription(
            this.state.userId,
            pricingPlanId,
            stationsToAdd,
            endDateMillis.valueOf(),
            amount,
            method,
            username,
            phone)
            .then((response) => {
                this.setState({showManualSubscriptionModal: false});
                this.handleManualSubscriptionResponse(response);
            });
    }

    /***************************************************************************** **/

    /**************************** MANUAL SUBSCRIPTION ****************************/
    /***************************************************************************** **/
    handleManualSubscriptionResponse(data) {
        console.log('handleManualSubscriptionResponse: ', data);
        let userData = this.state.userData;
        userData.user.pricingPlanId = data.pricingPlanId;
        userData.user.currentSubscription.subscriptionCancelled = data.subscriptionCancelled;
        userData.user.subscriptionPaymentId = data.subscriptionPaymentId;
        userData.user.currentSubscription.subscriptionEndDate = data.subscriptionEndDate;
        userData.user.paymentLink = data.paymentLink;
        userData.user.freePassUntil = data.freePassUntil;
        userData.user.isBillingBlocked = data.isBillingBlocked;
        this.handleUserResponse(userData, this.state.userId);
        this.showManualSubscriptionSuccessAlert();
    }

}

export default User;
