import React, {Component, createRef} from 'react';
import EventNoteIcon from '@material-ui/icons/EventNote';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Typography from '@material-ui/core/Typography';
import Moment from 'react-moment';
import momentTz from 'moment-timezone';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Network from "../../network/network";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SvgIcon from "@material-ui/core/es/SvgIcon/SvgIcon";
import {Link} from "react-router-dom";
import Checkbox from '@material-ui/core/Checkbox';
import UserApi from "../../network/userApi";
import UserUtils from "../../utils/userUtils";


const colStyle = {
    flexBasis: 0,
    flexGrow: 1
};

const rowStyle = {
    display: 'flex',
};

const buttonGroupStyle = {
    margin: 'auto',
    component: 'fieldset'
};

const copyToClipboardButtonStyle = {
    textAlign: 'left',
    alignSelf: 'center'
};

const textAreaStyle = {
    width: '530px'
};

class MismatchCard extends Component {

    state = {
        contact: null,
        selectedOption: '',
        setCopySuccess: '',
        startDate: null,

    };

    constructor(props) {
        super(props);
        this.textAreaRef = createRef();
        this.state = {
            contact: this.props.contact,
            selectedOption: UserUtils.getInstance().getBlockingType(this.props.contact),
            setCopySuccess: '',
            startDate: Date.now(),
            interactionChecked: !!this.props.contact.billingInteractionOccurred ? this.props.contact.billingInteractionOccurred : false
        };
    }

    handleDateChangedChange = date => {
        this.setState({
            startDate: date
        });
        this.updateFreePass(new Date(date).getTime())
    };

    handleCheckboxChange = (event) => {
        this.setState({
            interactionChecked: event.target.checked
        });
        this.updateInteractionOccurred(event.target.checked);
    };

    handleChange = (event) => {

        let contact = this.props.contact;

        if (event.target.value !== "RELEASE" && this.isFreePAssValid()) {
            window.alert(`Free pass until: ${new Date(contact.freePassUntil).toLocaleString()}`);
        } else {
            switch (event.target.value) {
                case "RELEASE": {
                    this.releaseUser(contact);
                    break;
                }
                case "TIMEOUT BLOCK": {
                    this.blockUser(contact, 0);
                    break;
                }
                case "HARD BLOCK": {
                    this.blockUser(contact, 1);
                    break;
                }
            }
        }
    };

    copyToClipboard(e) {
        this.textAreaRef.current.select();
        document.execCommand('copy');
        // This is just personal preference.
        // I prefer to not show the the whole text area selected.
        e.target.focus();
        this.setState({
            setCopySuccess: 'Copied!'
        });
    }

    onRemoveFreePass() {
        this.removeFreePass(this.props.contact)
    }

    blockUser(contact, blockingType) {
        fetch(`${Network.getInstance().getBaseUrl()}subscription/block`, {
            method: "POST",
            body: JSON.stringify({
                userId: contact.userId,
                blockingType: blockingType,
                paymentLink: this.createBillingUrl(contact)
            }),
            headers: Network.getInstance().getHeaders()
        })
            .then((response) => {
                if (Network.getInstance().isTokenExpiredOrInvalid(response.status)) {
                    Network.getInstance().removeAuthentication();
                    return null;
                }
                return response.json();
            })
            .then((data) => {
                try {
                    this.handleBlockingResponse(contact, data);

                } catch (e) {
                    console.log(e)
                }
            })
            .catch(console.log);
    }

    releaseUser(contact) {
        UserApi.getInstance().releaseUser(contact.userId)
            .then((data) => {
                try {
                    this.handleBlockingResponse(contact, data);

                } catch (e) {
                    console.log(e)
                }
            });
    }

    removeFreePass() {
        UserApi.getInstance().removeFreePass(this.props.contact.userId)
            .then(() => {
                try {
                    this.props.contact.freePassUntil = null;
                    this.setState({
                        contact: this.props.contact
                    });

                } catch (e) {
                    console.log(e)
                }
            });
    }

    updateFreePass(freePassTimestamp) {
        UserApi.getInstance().updateFreePass(freePassTimestamp, this.props.contact.userId, this.props.contact.subscriptionPaymentId)
            .then(() => {
                try {
                    this.props.contact.freePassUntil = freePassTimestamp;
                    this.setState({
                        contact: this.props.contact
                    });

                } catch (e) {
                    console.log(e)
                }
            });
    }

    updateNote(note) {
        UserApi.getInstance().updateNote(this.props.contact.userId, note)
            .then((data) => {
                try {
                    this.props.contact.paymentNote = data.paymentNote;
                    this.setState({
                        contact: this.props.contact
                    });

                } catch (e) {
                    console.log(e)
                }
            })
    }

    updateInteractionOccurred(interactionOccurred) {
        fetch(`${Network.getInstance().getBaseUrl()}subscription/modify`, {
            method: "POST",
            body: JSON.stringify({
                userId: this.props.contact.userId,
                billingInteractionOccurred: interactionOccurred,
                subscriptionPaymentId: this.props.contact.subscriptionPaymentId
            }),
            headers: Network.getInstance().getHeaders()
        })
            .then((response) => {
                if (Network.getInstance().isTokenExpiredOrInvalid(response.status)) {
                    Network.getInstance().removeAuthentication();
                    return null;
                }
                return response;
            })
            .then(() => {
                try {
                    this.props.contact.interactionOccurred = interactionOccurred;
                    this.setState({
                        contact: this.props.contact
                    });

                } catch (e) {
                    console.log(e)
                }
            })
            .catch(console.log);
    }

    deleteNote() {
        UserApi.getInstance().deleteNote(this.props.contact.userId)
            .then(() => {
                try {
                    this.props.contact.paymentNote = null;
                    this.setState({
                        contact: this.props.contact
                    });

                } catch (e) {
                    console.log(e)
                }
            })
    }

    createBillingUrl(contact) {
        return `https://billing.plannieapp.com/?isBillingMode=true&userId=${contact.userId}&pricingPlanId=${contact.pricingPlanIdToPay}&stationsToAdd=${contact.numOfStationsToPay}`;
    }

    handleBlockingResponse(contact, data) {
        if (contact.userId === data.userId) {
            this.props.contact.blockingType = data.blockingType;
            this.props.contact.isBillingBlocked = data.isBillingBlocked;
            this.props.onBlockStatechanged(data);

            this.setState({
                selectedOption: UserUtils.getInstance().getBlockingType(this.props.contact)
            });
        }
    }

    shouldShowCalendar() {
        return !this.props.contact.freePassUntil || this.props.contact.freePassUntil < new Date().getTime()
    }

    isFreePAssValid() {
        return this.props.contact.freePassUntil > new Date().getTime()
    }

    addNote() {
        let note = prompt("Please add your note");
        if (!!note && note !== "") {
            this.updateNote(note);
        } else {
            this.deleteNote();
        }
    }

    isCurrentSubscriptionDatePassed(currentSubscriptionEndDate) {
        return !!currentSubscriptionEndDate && new Date().getTime() > new Date(currentSubscriptionEndDate).getTime();
    }

    render() {

        let contact = this.props.contact;
        let copyText = this.state.setCopySuccess;

        return (
            <div>
                <Card style={{margin: '10px'}}>

                    <CardContent>

                        <Row style={{display: 'flex', alignItems: 'center'}}>
                            <Col>
                                <Typography component="p">
                                    Update date:
                                </Typography>
                            </Col>
                            <Col style={{marginLeft: '5px'}}>
                                <Typography>
                                    <Moment
                                        format="DD/MM/YY HH:mm">{momentTz(contact.updateDate).tz("Asia/Jerusalem")}</Moment>
                                </Typography>
                            </Col>
                        </Row>

                        <Row style={{display: 'flex', alignItems: 'center'}}>
                            <Col>
                                <Typography variant="h5" component="h2">
                                    {contact.userName}
                                </Typography>
                            </Col>
                            <Col style={{marginLeft: '10px'}}>
                                <Typography variant="h5" component="h2">
                                    ({contact.email})
                                </Typography>
                            </Col>

                            <Col style={{marginLeft: '10px'}}>
                                <Link to={{pathname: `/main/user/`, search: `?q=${contact.userId}`}} target="_blank">
                                    <button type="button">
                                        SHOW
                                    </button>
                                </Link>
                            </Col>

                        </Row>

                        <Typography variant="body2" component="p">
                            {contact.clientType} | {contact.appVersion}
                        </Typography>

                        <Typography variant="body2" component="p">
                            Customers: {contact.numOfCustomers}
                        </Typography>

                        <Container style={{marginTop: '0.5vw'}}>
                            <Row style={rowStyle}>
                                <Col style={colStyle}>
                                    <Typography variant="subtitle1" component="h3">
                                        <text style={{fontWeight: 'bold'}}> Services</text>
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        Allowed services: {contact.currentAllowedServices}
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        Defined services: {contact.currDefinedServices}
                                    </Typography>
                                </Col>

                                <Col style={colStyle}>
                                    <Typography variant="subtitle1" component="h3">
                                        <text style={{fontWeight: 'bold'}}> Stations</text>
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        Paid stations: {contact.currentPaidStations}
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        Active stations: {contact.currActiveStations}
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        Num of stations to pay for: {contact.numOfStationsToPay}
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        Has paired station: {contact.hasPairedStation ? "true" : "false"}
                                    </Typography>
                                </Col>
                                <Col style={colStyle}>
                                    <Typography variant="subtitle1" component="h3">
                                        <text style={{fontWeight: 'bold'}}> Billing</text>
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        Current pricing plan: {contact.currentBillingPlanId}
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        Pricing plan to pay: {contact.pricingPlanIdToPay}
                                    </Typography>
                                </Col>
                                <Col style={colStyle}>
                                    <Typography variant="subtitle1" component="h3">
                                        <text style={{fontWeight: 'bold'}}> SMS</text>
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        Enabled: {contact.isSmsEnabled ? "true" : "false"}
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        Usage: {contact.smsUsageCounter}
                                    </Typography>
                                </Col>
                                <Col style={colStyle}>
                                    <Typography variant="subtitle1" component="h3">
                                        <text style={{fontWeight: 'bold'}}> Other</text>
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        Web access enabled: {contact.hasWebAccess ? "true" : "false"}
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        Branding enabled: {contact.hasBranding ? "true" : "false"}
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        Reminders enabled: {contact.isRemindersEnabled ? "true" : "false"}
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        Who viewed my profile: {contact.hasWhoViewedMyProfile ? "true" : "false"}
                                    </Typography>
                                </Col>
                            </Row>

                            <Row style={{display: 'flex', alignItems: 'center', marginTop: '20px'}}>
                                <Col>
                                    <EventNoteIcon style={{color: 'green', fontSize: '35px'}}
                                                   onClick={() => this.addNote()}/>
                                </Col>
                                <Col style={{marginLeft: '10px'}}>
                                    <Typography variant="body2" component="p" align="center">
                                        <text style={{
                                            fontSize: '17px', wordWrap: "break-word"
                                        }}>  {contact.paymentNote} </text>
                                    </Typography>
                                </Col>
                            </Row>


                            <FormControlLabel style={{marginTop: '5px'}}
                                              control={
                                                  <Checkbox
                                                      checked={this.state.interactionChecked}
                                                      onChange={this.handleCheckboxChange}
                                                      name="interaction"
                                                      color="primary"
                                                  />
                                              }
                                              label="Interaction Occurred"
                            />

                            <Row style={{display: 'flex', alignItems: 'center'}}>
                                <Col>
                                    <Typography variant="subtitle1" component="h3">
                                        <text style={{fontWeight: 'bold'}}> Current subscription end date:</text>
                                    </Typography>
                                </Col>
                                <Col style={{marginLeft: '20px'}}>
                                    {
                                        this.isCurrentSubscriptionDatePassed(contact.currentSubscriptionEndDate)
                                            ?
                                            <Typography variant="body2" component="p">
                                                <text style={{
                                                    color: 'red',
                                                    fontWeight: 'bold'
                                                }}>  {!!contact.currentSubscriptionEndDate ? new Date(contact.currentSubscriptionEndDate).toLocaleString() : ''} </text>
                                            </Typography>
                                            :
                                            <Typography variant="body2" component="p">
                                                <text style={{
                                                    color: 'green',
                                                    fontWeight: 'bold'
                                                }}>  {!!contact.currentSubscriptionEndDate ? new Date(contact.currentSubscriptionEndDate).toLocaleString() : ''} </text>
                                            </Typography>
                                    }

                                </Col>
                            </Row>

                            <Row style={{display: 'flex', alignItems: 'center'}}>
                                <Col>
                                    <Typography variant="subtitle1" component="h3">
                                        <text style={{fontWeight: 'bold'}}> Lifetime in days:</text>
                                    </Typography>
                                </Col>
                                <Col style={{marginLeft: '20px'}}>
                                    <Typography variant="body2" component="p">
                                        <text style={{
                                            color: 'blue',
                                            fontWeight: 'bold'
                                        }}>  {contact.userLifeTimeInDays} </text>
                                    </Typography>
                                </Col>
                            </Row>

                            <Row style={{display: 'flex', alignItems: 'center'}}>
                                <Col>
                                    <Typography variant="subtitle1" component="h3">
                                        <text style={{fontWeight: 'bold'}}> Free Pass Until:</text>
                                    </Typography>
                                </Col>
                                <Col style={{marginLeft: '20px', alignItems: 'center'}}>
                                    <Typography variant="body2">
                                        {this.shouldShowCalendar() ?

                                            <DatePicker
                                                onChange={this.handleDateChangedChange}
                                            />

                                            :

                                            <Row style={{display: 'flex', alignItems: 'center', marginTop: '20px'}}>
                                                <Col style={colStyle}>
                                                    <text style={{
                                                        color: 'green',
                                                        fontWeight: 'bold'
                                                    }}>  {new Date(contact.freePassUntil).toLocaleString()} </text>
                                                </Col>
                                                <Col style={colStyle}>
                                                    <Button style={{padding: '0'}}
                                                            variant={'contained'}
                                                            onClick={this.onRemoveFreePass.bind(this)}>X</Button>
                                                </Col>
                                            </Row>


                                        }
                                    </Typography>
                                </Col>
                            </Row>

                        </Container>

                    </CardContent>

                    <CardActions>
                        <Col>
                            <div>
                                <Col>
                                    {
                                        document.queryCommandSupported('copy') &&
                                        <div>
                                            <Button
                                                variant={'contained'}
                                                onClick={this.copyToClipboard.bind(this)} ref={this.textAreaRef}>Copy to
                                                clipboard</Button>
                                            {copyText}
                                        </div>
                                    }
                                    <form>
                                        <TextareaAutosize
                                            style={textAreaStyle}
                                            rowsMax={'2'}
                                            ref={this.textAreaRef}
                                            value={this.createBillingUrl(contact)}
                                        />
                                    </form>
                                </Col>
                            </div>
                            <br/>
                            <FormControl style={buttonGroupStyle}>
                                <RadioGroup row value={UserUtils.getInstance().getBlockingType(contact)}
                                            onChange={this.handleChange}>
                                    <FormControlLabel value={"RELEASE"} control={<Radio/>} label="RELEASE"/>
                                    <FormControlLabel value={"TIMEOUT BLOCK"} control={<Radio/>} label="TIMEOUT BLOCK"/>
                                    <FormControlLabel value={"HARD BLOCK"} control={<Radio/>} label="HARD BLOCK"/>
                                </RadioGroup>
                            </FormControl>
                        </Col>
                    </CardActions>
                </Card>
            </div>
        )
    }
}

export default MismatchCard
