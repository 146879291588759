import React, {useState, useEffect, useContext} from 'react';
import Container from '@material-ui/core/Container';
import {Context} from "../../states/Store";
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Network from "../../network/network";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import SubscriptionCard from "./subscriptionCard";
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import {Link} from "react-router-dom";
import Button from '@material-ui/core/Button'
import Cookies from "universal-cookie";

function AgentReport() {

    const [state, dispatch] = useContext(Context);
    const [agent, setAgent] = useState(null);

    const theme = createMuiTheme({
        direction: 'rtl', // Both here and <body dir="rtl">
    });

    function isLoggedIn() {
        return !!agent
    }

    function getHour() {
        const date = new Date();
        const hour = date.getHours();
        if (hour < 12) return 'בוקר טוב';
        else if (hour < 18) return 'אחה״צ טובים';
        else return 'ערב טוב';
    }

    function getAgent() {
        fetch(`${Network.getInstance().getBaseUrl()}agents/sales/${state.user.agentId}`, {
            method: "GET",
            headers: Network.getInstance().getHeaders()
        })
            .then(function (response) {
                if (Network.getInstance().isTokenExpiredOrInvalid(response.status)) {
                    Network.getInstance().removeAuthentication();
                    return null;
                }
                return response.json();
            })
            .then(function (data) {
                try {
                    setAgent(data);

                } catch (e) {
                    console.log(e)
                }
            })
            .catch(function(e) {
                console.log(e);
            });

    }

    function dailySalesTarget() {
        return Number(agent.monthlyGoal / agent.monthWorkingDays).toFixed(0);
    }

    function getTotalDailySales() {
        let dayInMonth = new Date().getDate();
        let totalDailySales = 0;

        agent.agentSubscriptions.filter((subscription) => (
            new Date(subscription.startDate).getDate() === dayInMonth)
        ).map((subscription) => (
            totalDailySales += Number(subscription.amount)
        ));

        return totalDailySales;
    }

    if (!state.error && state.user && !agent) {
        getAgent();
    }

    function getExpectedPacePerMonth() {
        let now = new Date();
        let dayInMonth = now.getDate();
        let numOfDaysInMonth = new Date(now.getFullYear(), agent.monthNumber, 0).getDate();

        // A special case for the date Liat started. Should be removed when July ends.
        if (now.getMonth()+1 === 7){
            numOfDaysInMonth = 10;
            dayInMonth = dayInMonth % 19;
        }

        let pace = dayInMonth / numOfDaysInMonth * agent.monthlyGoal;
        return Number(pace).toFixed(0);
    }

    return (
        <ThemeProvider theme={theme}>
            <Container fixed dir="rtl">

                {!isLoggedIn() ?
                    <div style={{marginTop: '30%', position: "fixed", width: '100%', textAlign: 'center'}}>
                        <CircularProgress/>
                    </div>

                    :

                    <div>
                        <Row style={{display: 'flex', alignItems: 'center', marginTop: '2vw'}}>
                            <Col>
                                <Typography style={{fontSize: '3vw'}} variant="h3">
                                    {getHour()}<span>&nbsp;&nbsp;</span>
                                </Typography>
                            </Col>
                            <Col style={{marginLeft: '1vw'}}>
                                <Typography style={{fontSize: '3vw', fontWeight: 'bold'}} variant="h3">
                                    {state.user.userName}
                                </Typography>
                            </Col>
                        </Row>

                        <Row style={{display: 'flex', alignItems: 'center', marginTop: '2vw'}}>
                            <Col>
                                <Typography style={{fontSize: '2vw'}} variant="h3">
                                    חודש:<span>&nbsp;&nbsp;</span>
                                </Typography>
                            </Col>
                            <Col style={{marginLeft: '10px'}}>
                                <Typography style={{fontSize: '2vw', fontWeight: 'bold', color: 'blue'}} variant="h3">
                                    {agent.monthNumber}
                                </Typography>
                            </Col>
                        </Row>

                        <Row style={{display: 'flex', alignItems: 'center', marginTop: '3vw'}}>
                            <Col>
                                <Typography style={{fontSize: '2vw'}} variant="h3">
                                    יעד חודשי (₪):<span>&nbsp;&nbsp;</span>
                                </Typography>
                            </Col>
                            <Col style={{marginLeft: '10px'}}>
                                <Typography style={{fontSize: '2vw', fontWeight: 'bold', color: 'green'}} variant="h3">
                                    {agent.monthlyGoal}
                                </Typography>
                            </Col>
                        </Row>

                        <Row style={{display: 'flex', alignItems: 'center', marginTop: '3vw'}}>
                            <Col>
                                <Typography style={{fontSize: '2vw'}} variant="h3">
                                    מספר ימי עבודה בחודש:<span>&nbsp;&nbsp;</span>
                                </Typography>
                            </Col>
                            <Col style={{marginLeft: '10px'}}>
                                <Typography style={{fontSize: '2vw', fontWeight: 'bold', color: 'green'}} variant="h3">
                                    {agent.monthWorkingDays}
                                </Typography>
                            </Col>
                        </Row>

                        <Typography style={{fontSize: '2vw', marginTop: '1.5vw'}} variant="h3">
                            ---------------------------------------------------------------------
                        </Typography>

                        <Row style={{display: 'flex', alignItems: 'center', marginTop: '1.5vw'}}>
                            <Col>
                                <Typography style={{fontSize: '2vw'}} variant="h3">
                                    יעד מכירות יומי:<span>&nbsp;&nbsp;</span>
                                </Typography>
                            </Col>
                            <Col style={{marginLeft: '10px'}}>
                                <Typography style={{fontSize: '2vw', fontWeight: 'bold', color: 'blue'}} variant="h3">
                                    {dailySalesTarget()}
                                </Typography>
                            </Col>
                        </Row>


                        <Row style={{display: 'flex', alignItems: 'center', marginTop: '1.5vw'}}>
                            <Col>
                                <Typography style={{fontSize: '2vw'}} variant="h3">
                                    סך מכירות היום:<span>&nbsp;&nbsp;</span>
                                </Typography>
                            </Col>
                            <Col style={{marginLeft: '10px'}}>
                                <Typography style={{fontSize: '2vw', fontWeight: 'bold', color: 'green'}} variant="h3">
                                    {getTotalDailySales()}
                                </Typography>
                            </Col>
                        </Row>

                        <Typography style={{fontSize: '2vw', marginTop: '1.5vw'}} variant="h3">
                            ---------------------------------------------------------------------
                        </Typography>

                        <Col style={{display: 'flex', alignItems: 'center', marginTop: '1.5vw'}}>
                            <Row style={{display: 'flex', alignItems: 'center'}}>
                                <Col>
                                    <Typography style={{fontSize: '2vw'}} variant="h3">
                                        סך מכירות חודשיות / מכירות חודשיות מצופות עד היום:
                                    </Typography>
                                </Col>

                                {agent.totalMonthlySales < getExpectedPacePerMonth()

                                    ?

                                    <Col style={{marginLeft: '10px', alignItems: 'center'}}>
                                        <Row style={{display: 'flex', alignItems: 'center'}}>

                                            <Col>
                                                <Typography style={{fontSize: '2vw', fontWeight: 'bold', color: 'red'}}
                                                            variant="h3">
                                                    <span>&nbsp;&nbsp;</span>{agent.totalMonthlySales}
                                                </Typography>
                                            </Col>

                                            <Col style={{marginLeft: '10px'}}>
                                                <Typography style={{fontSize: '2vw', fontWeight: 'bold'}}
                                                            variant="h3">
                                                    /
                                                </Typography>
                                            </Col>

                                            <Col style={{marginLeft: '10px'}}>
                                                <Typography style={{fontSize: '2vw', fontWeight: 'bold', color: 'blue'}}
                                                            variant="h3">
                                                    {getExpectedPacePerMonth()}
                                                </Typography>
                                            </Col>

                                            <Col>
                                                <Typography style={{fontSize: '2vw', color: 'red'}} variant="h3">
                                                    <span>&nbsp;&nbsp;</span> ({getExpectedPacePerMonth() > 0 ? Number(agent.totalMonthlySales / getExpectedPacePerMonth() * 100).toFixed(0) : 0}%)
                                                </Typography>
                                            </Col>

                                        </Row>
                                    </Col>

                                    :

                                    <Col style={{marginLeft: '10px'}}>
                                        <Row style={{display: 'flex', alignItems: 'center'}}>

                                            <Col>
                                                <Typography
                                                    style={{fontSize: '2vw', fontWeight: 'bold', color: 'green'}}
                                                    variant="h3">
                                                    <span>&nbsp;&nbsp;</span>{agent.totalMonthlySales}
                                                </Typography>
                                            </Col>

                                            <Col style={{marginLeft: '10px'}}>
                                                <Typography style={{fontSize: '2vw', fontWeight: 'bold'}}
                                                            variant="h3">
                                                    /
                                                </Typography>
                                            </Col>

                                            <Col style={{marginLeft: '10px'}}>
                                                <Typography style={{fontSize: '2vw', fontWeight: 'bold', color: 'blue'}}
                                                            variant="h3">
                                                    {getExpectedPacePerMonth()}
                                                </Typography>
                                            </Col>

                                            <Col>
                                                <Typography
                                                    style={{fontSize: '2vw', fontWeight: 'bold', color: 'green'}}
                                                    variant="h3">
                                                    <span>&nbsp;&nbsp;</span> ({getExpectedPacePerMonth() > 0 ? Number(agent.totalMonthlySales / getExpectedPacePerMonth() * 100).toFixed(0) : 0}%)
                                                </Typography>
                                            </Col>

                                        </Row>
                                    </Col>

                                }
                            </Row>
                        </Col>

                        <Typography style={{fontSize: '2vw', marginTop: '1.5vw'}} variant="h3">
                            ---------------------------------------------------------------------
                        </Typography>

                        <Typography style={{fontSize: '3vw', fontWeight: 'bold', marginTop: '3vw'}} variant="h3">
                            <text style={{textDecorationLine: 'underline'}}> קישורים שימושיים:</text>
                        </Typography>

                        <Link style={{ textDecoration: 'none'}} to={{pathname: `https://www.plannie.co.il/%d7%aa%d7%9b%d7%a0%d7%99%d7%95%d7%aa-%d7%95%d7%9e%d7%97%d7%99%d7%a8%d7%99%d7%9d/`}} target="_blank">
                            <Button style={{marginTop: '1.5vw', fontSize: '2vw'}}
                                    variant={'contained'}
                                    >תכניות ומחירים</Button>
                        </Link>

                        <Link style={{ textDecoration: 'none'}} to={{pathname: `https://www.plannie.co.il/%d7%9e%d7%a8%d7%9b%d7%96-%d7%94%d7%aa%d7%9e%d7%99%d7%9b%d7%94/`}} target="_blank">
                            <Button style={{marginRight: '1vw', marginTop: '1.5vw', fontSize: '2vw'}}
                                    variant={'contained'}
                            >סרטוני הדרכה</Button>
                        </Link>

                        <Link style={{ textDecoration: 'none'}} to={{pathname: `https://biliblond.com/pages/stores`}} target="_blank">
                            <Button style={{marginRight: '1vw', marginTop: '1.5vw', fontSize: '2vw'}}
                                    variant={'contained'}
                            >מיתוג עסק - ביליבלונד</Button>
                        </Link>

                        <Typography style={{fontSize: '3.5vw', fontWeight: 'bold', marginTop: '3vw'}} variant="h3">
                            מנויים חודשיים:
                        </Typography>
                        <div>
                            {
                                agent.agentSubscriptions.map((subscription) => (
                                    <SubscriptionCard subscription={subscription}/>
                                ))
                            }
                        </div>

                    </div>
                }

            </Container>
        </ThemeProvider>
    )
}


export default AgentReport
