import fetch from "cross-fetch";
import Network from "./network";

export default class SystemApi {

    static instance = null;

    static getInstance() {
        if (SystemApi.instance == null) {
            SystemApi.instance = new SystemApi();
        }

        return this.instance;
    }

    updateRestrictedParams(android, ios, web) {
        return fetch(`${Network.getInstance().getBaseUrl()}params`, {
            method: "PUT",
            body: JSON.stringify({
                paramId: '22',
                paramValue: {
                    'android-hybrid': android,
                    'ios-hybrid': ios,
                    'web': web
                }
            }),
            headers: Network.getInstance().getHeaders()
        })
            .then(function (response) {
                if (Network.getInstance().isTokenExpiredOrInvalid(response.status)) {
                    Network.getInstance().removeAuthentication();
                    return null;
                }
                return response;
            })
            .catch(console.log);
    }
}
