import React, {Component} from 'react';

import SimpleCard from "../users/card";
import ButtonAppBar from "../appBar";
import CircularProgress from "@material-ui/core/CircularProgress";
import SimpleTable from "../dashboard/dashboardTable";
import momentTz from 'moment-timezone';
import {Doughnut} from "react-chartjs-2";
import Network from "../../network/network";


class Report extends Component {

    render() {
        return (
            <div>
                <div style={{marginTop: '30%', position: "fixed", width: '100%', textAlign: 'center'}}>
                    {this.state.reportsAvgRow.length === 0 ? <CircularProgress/> : null}
                </div>
                <div>
                    {/*<SimpleCard contacts={this.state.usersToShow}/>*/}
                    <SimpleTable style={{marginTop: '10px'}} rows={this.state.reportsAvgRow} titles={["Averages"]}/>
                    <SimpleTable style={{marginTop: '10px'}} rows={this.state.reportsMedRow} titles={["Medians"]}/>
                    <div style={{paddingBottom: '25px', paddingTop: '50px'}}> </div>

                    <div style={{paddingBottom: '100px', paddingTop: '50px'}}>
                        <Doughnut
                            data={this.state.professionsForChart}
                            // width={"10px"}
                            height={300}
                            // options={{ maintainAspectRatio: false }}
                        />
                    </div>

                </div>

            </div>
        )
    }

    state = {
        reportsAvgRow: [],
        reportsMedRow: [],
        professionsForChart: [],
    };

    handleReportResponse(data) {
        let reportsAvgRow = [];
        let reportsMedRow = [];
        let professions = data.professions;

        reportsAvgRow.push({"name": "Activity Days", "val": data.DaysRegisteredAvg.toFixed(1)});
        reportsAvgRow.push({"name": "Monthly Income", "val": "$"+data.MonthlyIncomeAvg});
        reportsAvgRow.push({"name": "latest Approved Event In Days", "val": data.latestApprovedEventInDaysAvg.toFixed(1)});
        reportsAvgRow.push({"name": "Events Approved", "val": data.EventsApprovedAvg.toFixed(1)});
        reportsAvgRow.push({"name": "Events Approved Manual", "val": data.EventsApprovedManualAvg.toFixed(1)});
        reportsAvgRow.push({"name": "Events Approved Manual Ratio", "val": (data.EventsApprovedManualAvg / data.EventsApprovedAvg * 100).toFixed(2) + "%"});
        reportsAvgRow.push({"name": "Customers", "val": data.CustomersAvg.toFixed(1)});
        reportsAvgRow.push({"name": "Events Per Day", "val": (data.EventsApprovedAvg / data.DaysRegisteredAvg).toFixed(1)});

        reportsMedRow.push({"name": "Activity Days", "val": data.DaysRegisteredMedian.toFixed(1)});
        reportsMedRow.push({"name": "Monthly Income", "val": "$"+data.MonthlyIncomeMedian});
        reportsMedRow.push({"name": "latest Approved Event In Days", "val": data.latestApprovedEventInDaysMedian.toFixed(1)});
        reportsMedRow.push({"name": "Events Approved", "val": data.EventsApprovedMedian.toFixed(1)});
        reportsMedRow.push({"name": "Events Approved Manual", "val": data.EventsApprovedManualMedian.toFixed(1)});
        reportsMedRow.push({"name": "Events Approved Manual Ratio", "val": (data.EventsApprovedManualMedian / data.EventsApprovedMedian * 100).toFixed(2) + "%"});
        reportsMedRow.push({"name": "Customers", "val": data.CustomersMedian.toFixed(1)});
        reportsMedRow.push({"name": "Events Per Day", "val": (data.EventsApprovedMedian / data.DaysRegisteredMedian).toFixed(1)});

        let professionsForChart = {
            labels: [],
            datasets: [{
                data: [],
                backgroundColor: [],
                hoverBackgroundColor: []
            }]
        };

        professions.forEach(function (profession) {

            if (profession.count > 0) {
                professionsForChart.labels.push(profession.professionVariants[0]);
                professionsForChart.datasets[0].data.push(profession.count);
                var rgb = [];
                for (var i = 0; i < 3; i++) {
                    rgb.push(Math.floor(Math.random() * 255));
                }
                let chosenColor = 'rgb(' + rgb.join(',') + ')';
                professionsForChart.datasets[0].backgroundColor.push(chosenColor);
                professionsForChart.datasets[0].hoverBackgroundColor.push(chosenColor);
            }

        });

        this.setState({professionsForChart: professionsForChart});
        this.setState({reportsAvgRow: reportsAvgRow});
        this.setState({reportsMedRow: reportsMedRow});
    }


    componentDidMount() {
        let report = this.props.location.state.report;

        fetch(`${Network.getInstance().getBaseUrl()}users/batchAnalytics`, {
            method: "POST",
            headers: Network.getInstance().getHeaders(),
            body: JSON.stringify({userIds: report}),
        })
            .then(function (response) {
                if (Network.getInstance().isTokenExpiredOrInvalid(response.status)) {
                    Network.getInstance().removeAuthentication();
                    return null;
                }
                return response.json();
            })
            .then(function (data) {
                try {
                    this.handleReportResponse(data);

                } catch (e) {
                    console.log(e)
                }
            }.bind(this))
            .catch(console.log);

    }



}


export default Report
