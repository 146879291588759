import React, {Component} from 'react';

import MismatchCard from "./mismatchCard";
import ButtonAppBar from "../appBar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Network from "../../network/network";

class plansMismatch extends Component {

    constructor(props) {
        super(props);

        this.state = localStorage.getItem("appState") ? JSON.parse(localStorage.getItem("appState")) : this.state;
        localStorage.removeItem("appState");
    }

    handleBlockStateChanged = (updatedUser) => {
        // updatedUser = this.state.users.filter(user => user.userId === updatedUser.userId);
        let userIndex = this.state.users.findIndex(user => user.userId === updatedUser.userId);
        if (updatedUser.length > -1) {
            let newState = Object.assign({}, this.state);
            newState.users[userIndex].blockingType = updatedUser.blockingType;
            newState.users[userIndex].isBillingBlocked = updatedUser.isBillingBlocked;
            this.setState(newState);
        }
    };

    render() {
        const items = this.state.usersToShow.map((contact) => (
            <MismatchCard contact={contact} onBlockStatechanged={this.handleBlockStateChanged}/>
        ));
        return (
            <div>
                <div style={{marginTop: '30%', position: "fixed", width: '100%', textAlign: 'center'}}>
                    {this.state.usersToShow.length === 0 ? <CircularProgress/> : null}
                </div>
                {<ButtonAppBar searchTextChanged={this.searchTextChanged}
                               filterWasSet={this.filterWasSet}
                               payingSwitchEnabled={this.state.mismatchSwitchEnabled}
                               payingSwitchLabel={this.mismatchSwitchLabel}
                               onPayingSwitchChange={this.onMismatchSwitchChange}
                >
                </ButtonAppBar>}
                <div>
                    {items}
                </div>
            </div>
        )
    }

    searchTextChanged = (newText) => {

        let newUsers = this.state.users;

        if (newText === 'convert') {
            newUsers = newUsers.filter(user => user.numOfCustomers === 0);
        } else if (newText === 'web') {
            newUsers = newUsers.filter(user => user.clientType === 'web');
        } else if (newText === 'timeout') {
            newUsers = newUsers.filter(user => user.blockingType === 0);
        } else if (newText === 'hard') {
            newUsers = newUsers.filter(user => user.blockingType === 1);
        } else if (newText === 'release') {
            newUsers = newUsers.filter(user => !user.blockingType);
        } else {
            newUsers = newUsers.filter(user => user.userName.toLowerCase().includes(newText) || (!!user.appVersion && user.appVersion.startsWith(newText))
                || user.email.toLowerCase().includes(newText)
            );
        }

        if (newUsers.length > 0) {
            this.setState({usersToShow: newUsers})
        } else {
            this.setState({
                usersToShow: [{
                    userName: "No Results"
                }]
            })
        }
        // user=> user.email.includes(newText) || user.userName.includes(newText) || user._id.includes(newText) || user.profession.includes(newText)
    };

    mismatchSwitchLabel = () => {
        return this.state.mismatchSwitchEnabled
            ?
            `Paying (${this.state.usersToShow.length})`
            :
            `Non-Paying (${this.state.usersToShow.length})`
    }

    onMismatchSwitchChange = (checked) => {
        this.setState({mismatchSwitchEnabled: checked})
        this.filterPaying(checked);
    }

    filterPaying = (paying) => {
        let newUsers = this.state.users;
        if (paying) {
            newUsers = newUsers.filter(user => parseInt(user.currentBillingPlanId) > 0);
        } else {
            newUsers = newUsers.filter(user => parseInt(user.currentBillingPlanId) === 0);
        }

        if (newUsers.length > 0) {
            this.setState({usersToShow: newUsers})
        }
    }

    filterWasSet = (newFilter) => {
        this.setState({currentFilter: newFilter});

    };

    isUserLiseEmpty() {
        return this.state.usersToShow.length === 0;
    }

    state = {
        users: [],
        usersToShow: [],
        dataItem: [],
        cache: [],
        currentFilter: 'name',
        mismatchSwitchEnabled: false
    };

    componentDidMount() {
        if (this.isUserLiseEmpty()) {
            fetch(`${Network.getInstance().getBaseUrl()}subscription/mismatches`, {
                method: "GET",
                headers: Network.getInstance().getHeaders()
            })
                .then(function (response) {
                    if (Network.getInstance().isTokenExpiredOrInvalid(response.status)) {
                        Network.getInstance().removeAuthentication();
                        return null;
                    }
                    return response.json();
                })
                .then(function (data) {
                    try {
                        this.setState({users: data.result});
                        this.setState({usersToShow: data.result});
                        this.filterPaying(this.state.mismatchSwitchEnabled);

                    } catch (e) {
                        console.log(e)
                    }
                }.bind(this))
                .catch(console.log);
        }
    }
}


export default plansMismatch
