import React, {Component} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Moment from 'react-moment';
import momentTz from 'moment-timezone';
import {Link} from "react-router-dom";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },

    marginTen: {
        margin: 10
    }
});

class SimpleCard extends Component {

    render() {
        const contact = this.props.contact;
        const privileges = this.props.privileges;
        return (
            <div >
                <Link style={{ textDecoration: 'none' }} to={{pathname: `/main/user/`, search: `?q=${contact._id}&privileges=${privileges}`}} target="_blank">
                    <Card style={{margin: '10px'}}>
                        <CardContent>
                            <Typography color="textSecondary" gutterBottom>
                                <Moment
                                    format="DD/MM/YY HH:mm">{momentTz(contact.updateDate).tz("Asia/Jerusalem")}</Moment>
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {contact.userName} ({contact.profession})
                            </Typography>

                            <Typography variant="body2" component="p">
                                {contact.clientType} | {contact.appVersion}
                                {this.isChild(contact) ? "| Child |" : ""}
                                {this.isMaster(contact) ? "| Master |" : ""}
                                {" " + contact.timeZone}
                            </Typography>
                            {this.isPaying(contact) ?
                                <text style={{
                                    color: 'green',
                                    fontWeight: 'bold',
                                    fontSize: '15px'

                                }}> Paying user (plan #{contact.pricingPlanId}) </text>
                                :
                                <text style={{
                                    color: 'red',
                                    fontWeight: 'bold',
                                    fontSize: '15px'
                                }}>  NOT Paying User - Score: {this.getUserScore(contact)} </text>

                            }
                            {!this.isTrialEnded(contact) ?
                                <text style={{
                                    color: 'green',
                                    fontWeight: 'bold',
                                    fontSize: '15px'

                                }}> - On Trial {this.isClaimed(contact)}</text>
                                :
                                <text style={{
                                    color: 'red',
                                    fontWeight: 'bold',
                                    fontSize: '15px'
                                }}> - Trial Ended {this.isClaimed(contact)}</text>

                            }
                        </CardContent>
                        <CardActions>
                            <Button size="small">customers {contact.numOfCustomers} </Button>
                            <Button size="small">lifetime {contact.lifetimeInDays} </Button>
                            <Button size="small">Events {contact.eventsCounter} </Button>
                            <Button size="small">feedViews {contact.feedViews} </Button>
                        </CardActions>
                    </Card>
                </Link>
            </div>
        )
    }

    isClaimed(contact){
        return contact.isClaimed === true ? "(Claimed)" : ""
    }

    getUserScore(contact){
        if(contact.lifetimeInDays > 0 && contact.feedViews > 0){
            return ((contact.numOfCustomers + (contact.feedViews / 2) + contact.eventsCounter) / contact.lifetimeInDays).toFixed(2);
        }else{
            return 0;
        }
    }

    isBlocked(contact){
        return contact.isBillingBlocked
    }

    isTrialEnded(contact){
        return contact.lifetimeInDays > contact.trialPeriodInDays
    }

    getBlockingType(contact){
        return contact.blockingType === 0  || !contact.blockingType  ? "Timeout" : "Hard"
    }

    isPaying(contact) {
        return contact.pricingPlanId > 0;
    }

    isChild(contact){
        return !!contact.masterUserId;
    }

    isMaster(contact){
        return !!contact.childUserIds && contact.childUserIds.length > 0;
    }
}

export default SimpleCard
