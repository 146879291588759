import React, {Component, createRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Typography from '@material-ui/core/Typography';
import Moment from 'react-moment';
import momentTz from 'moment-timezone';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Network from "../../network/network";

const colStyle = {
    flexBasis: 0,
    flexGrow: 1
};

const rowStyle = {
    display: 'flex',
};

const buttonGroupStyle = {
    margin: 'auto',
    component: 'fieldset'
};

const copyToClipboardButtonStyle = {
    textAlign: 'left',
    alignSelf: 'center'
};

const textAreaStyle = {
    width: '530px'
};

function sendEmail(email) {
    window.open(`mailto:${email}`);
}

function getPlanNameByPlanId(planId) {
    switch (Number(planId)){
        case 1:
            return 'Plannie Plus';
        case 2:
            return 'Plannie Team';
        case 3:
            return 'Plannie Gold';
        default:
            return planId;
    }
}

const SubscriptionCard = (subscription) => {

    return (
        <div>
            <Card style={{margin: '15px', backgroundColor:'#f5f5f5'}}>

                <CardContent>
                    <Typography style={{fontSize: '3vw', fontWeight: 'bold'}}>
                        {subscription.subscription.Fild1}
                    </Typography>
                    <Row style={{display: 'flex', alignItems: 'center', marginTop: '1vw'}}>
                        <Col>
                            <Typography style={{fontSize: '2vw'}} variant="h3">
                                {subscription.subscription.Fild2}<span>&nbsp;&nbsp;</span>
                            </Typography>
                        </Col>
                        <Col style={{marginLeft: '10px'}}>
                            <button type="button"
                                style={{display: 'flex', minWidth: '1vw', fontSize: '1.5vw'}}
                                onClick={() => sendEmail(subscription.subscription.Fild2)}>שלח אימייל</button>
                        </Col>
                    </Row>

                    <Row style={{display: 'flex', alignItems: 'center', marginTop: '1vw'}}>
                        <Col>
                            <Typography style={{fontSize: '2vw'}} variant="h3">
                                טלפון:<span>&nbsp;&nbsp;</span>
                            </Typography>
                        </Col>
                        <Col style={{marginLeft: '10px'}}>
                            <Typography style={{fontSize: '2vw', fontWeight: 'bold'}} variant="h3">
                                {subscription.subscription.cell}
                            </Typography>
                        </Col>
                    </Row>

                    <Row style={{display: 'flex', alignItems: 'center', marginTop: '1vw'}}>
                        <Col>
                            <Typography style={{fontSize: '2vw'}} variant="h3">
                                תאריך התחלה:<span>&nbsp;&nbsp;</span>
                            </Typography>
                        </Col>
                        <Col style={{marginLeft: '10px'}}>
                            <Typography style={{fontSize: '2vw', fontWeight: 'bold'}} variant="h3">
                                {new Date(subscription.subscription.startDate).toLocaleString()}
                            </Typography>
                        </Col>
                    </Row>

                    <Row style={{display: 'flex', alignItems: 'center', marginTop: '1vw'}}>
                        <Col>
                            <Typography style={{fontSize: '2vw'}} variant="h3">
                                סכום:<span>&nbsp;&nbsp;</span>
                            </Typography>
                        </Col>
                        <Col style={{marginLeft: '10px'}}>
                            <Typography style={{fontSize: '2vw', fontWeight: 'bold', color: 'green'}} variant="h3">
                                {subscription.subscription.amount}
                            </Typography>
                        </Col>
                    </Row>

                    <Row style={{display: 'flex', alignItems: 'center', marginTop: '1vw'}}>
                        <Col>
                            <Typography style={{fontSize: '2vw'}} variant="h3">
                                מסלול:<span>&nbsp;&nbsp;</span>
                            </Typography>
                        </Col>
                        <Col style={{marginLeft: '10px'}}>
                            <Typography style={{fontSize: '2vw', fontWeight: 'bold', color: 'blue'}} variant="h3">
                                {getPlanNameByPlanId(subscription.subscription.pricingPlanId)}
                            </Typography>
                        </Col>
                    </Row>

                    <Row style={{display: 'flex', alignItems: 'center', marginTop: '1vw'}}>
                        <Col>
                            <Typography style={{fontSize: '2vw'}} variant="h3">
                                מספר תחנות נוספות:<span>&nbsp;&nbsp;</span>
                            </Typography>
                        </Col>
                        <Col style={{marginLeft: '10px'}}>
                            <Typography style={{fontSize: '2vw', fontWeight: 'bold', color: 'blue'}} variant="h3">
                                {subscription.subscription.stationsToAdd}
                            </Typography>
                        </Col>
                    </Row>

                </CardContent>

                <CardActions>
                </CardActions>
            </Card>
        </div>
    )
};

export default SubscriptionCard
