import fetch from "cross-fetch";
import Network from "./network";

export default class UsersApi {

    static instance = null;

    static getInstance() {
        if (UsersApi.instance == null) {
            UsersApi.instance = new UsersApi();
        }

        return this.instance;
    }

    fetchUsers() {
        return fetch(`${Network.getInstance().getBaseUrl()}users/users/all?take=550`, {
            method: "GET",
            headers: Network.getInstance().getHeaders()
        })
            .then(function (response) {
                if (Network.getInstance().isTokenExpiredOrInvalid(response.status)) {
                    Network.getInstance().removeAuthentication();
                    return null;
                }
                return response.json();
            })
            .catch(console.log);
    }

    searchBy(searchKey) {
        return fetch(`${Network.getInstance().getBaseUrl()}users/search?q=${searchKey}`, {
            method: "GET",
            headers: Network.getInstance().getHeaders()
        })
            .then(function (response) {
                if (Network.getInstance().isTokenExpiredOrInvalid(response.status)) {
                    Network.getInstance().removeAuthentication();
                    return null;
                }
                return response.json();
            })
            .catch(console.log);
    }
}
