import React from 'react';
import Redirect from "react-router-dom/es/Redirect";
import Cookies from 'universal-cookie';
import Route from "react-router-dom/es/Route";

const cookies = new Cookies();

const PrivateRoute = ({component: Component, ...rest}) => {
    return (

        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        <Route {...rest} render={props => (
            isLogin() ?
                <Component {...props} />
                : <Redirect to={{
                    pathname: "/login",
                    state: {from: props.location}
                }}/>
        )} />
    );
};

function isLogin() {
    return !!cookies.get("isAuthenticated")
}

export default PrivateRoute;