import React, {useRef, useState, useEffect, useContext} from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Input from "../custom/Input";
import DesignedButton from "../custom/designedButton";
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress';
import Network from "../../network/network";
import {Context} from '../../states/Store'
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import planniePlus from '../../assets/plannie-plus.png';
import plannieTeam from '../../assets/plannie-team.png';
import plannieGold from '../../assets/plannie-gold.png';
import "./subscription.css";

function AgentSubscription() {

    const [submit, setSubmit] = useState(false);
    const [state, dispatch] = useContext(Context);
    const [chosen, setChosen] = useState();

    const planIdRef = useRef();
    const stationsRef = useRef(null);
    const emailRef = useRef(null);

    const theme = createMuiTheme({
        direction: 'rtl', // Both here and <body dir="rtl">
    });

    function submitFormHandler() {
        setSubmit(true);
        getUserByEmail(emailRef.current.state.value);
    }

    function createBillingUrl(userId, plandId, numOfStations) {
        return `https://billing.plannieapp.com/?isBillingMode&userId=${userId}&pricingPlanId=${plandId}&stationsToAdd=${numOfStations}&agentId=${state.user.agentId}`;
    }

    function onSubmitSucceeded(userId) {
        window.open(createBillingUrl(userId, planIdRef.current.state.value, stationsRef.current.state.value));
        emailRef.current.state.value = '';
        planIdRef.current.state.value = '';
        stationsRef.current.state.value = '';
        setChosen(null)
    }

    function getUserByEmail(email) {

        fetch(`${Network.getInstance().getBaseUrl()}users/getBy`, {
            method: "POST",
            body: JSON.stringify({
                email: email
            }),
            headers: Network.getInstance().getHeaders()
        })
            .then(function (response) {
                if (Network.getInstance().isTokenExpiredOrInvalid(response.status)) {
                    Network.getInstance().removeAuthentication();
                    return null;
                }
                return response.json();
            })
            .then(function (data) {
                try {
                    if (!data.result) {
                        try {
                            onSubmitSucceeded(data._id);

                        } catch (e) {
                            console.log(e)
                        }
                    } else {
                        window.alert(data.result);
                    }
                    setSubmit(false);


                } catch (e) {
                    console.log(e)
                }
            }.bind(this))
            .catch(console.log);
    }

    function onPlanSelected(planId) {
        setChosen(planId);
        planIdRef.current.state.value = planId;
    }

    const Plan = ({active, src, planId}) => {
        return (
            <Col className={active ? "active" : ""}>
                <img src={src} width="100" onClick={() => onPlanSelected(planId)}/>
            </Col>
        );
    };

    return (
        <ThemeProvider theme={theme}>

            <div dir="rtl" style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <Col>
                    <Row>
                        <div dir="rtl" style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <form>
                                <Typography style={{
                                    display: 'flex',
                                    fontSize: '3vw',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginBottom: '1.5vw',
                                    marginTop: '1vw'
                                }} variant="h3">
                                    יצירת מנוי חדש
                                </Typography>

                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: '#81c784',
                                    padding: '1vw',
                                    width: '-webkit-fill-available'
                                }}>

                                    <Col style={{width: 'inherit'}}>
                                        <Row style={{display: 'flex'}}>
                                            <Input
                                                id={1}
                                                label="מספר מסלול"
                                                locked={false}
                                                active={false}
                                                type={"number"}
                                                ref={planIdRef}
                                            />
                                        </Row>
                                        <Row style={{display: 'flex', marginTop: '2vw'}}>
                                            <Input
                                                id={1}
                                                label="מספר עמדות נוספות"
                                                locked={false}
                                                active={false}
                                                type={"number"}
                                                ref={stationsRef}
                                            />
                                        </Row>
                                        <Row style={{display: 'flex', marginTop: '2vw'}}>
                                            <Input
                                                id={2}
                                                label="אימייל"
                                                locked={false}
                                                active={false}
                                                type={"email"}
                                                ref={emailRef}
                                            />
                                        </Row>
                                        <Row style={{display: 'flex', marginTop: '2vw'}}>
                                            <Button style={{display: 'flex', width: '-webkit-fill-available'}}
                                                    onClick={() => submitFormHandler()}>
                                                {
                                                    submit
                                                        ?
                                                        <div style={{width: '100%', textAlign: 'center'}}>
                                                            <CircularProgress/>
                                                        </div>
                                                        :
                                                        <DesignedButton
                                                            id={2}
                                                            label="עבור לדף תשלום"
                                                            predicted="California"
                                                            locked={true}
                                                            active={false}
                                                        />
                                                }
                                            </Button>
                                        </Row>
                                    </Col>
                                </div>
                            </form>


                        </div>
                    </Row>
                    <Row>
                        <Row style={{display: 'flex', alignItems: 'center', marginTop: '1vw', justifyContent: 'space-between'}}>
                            <Plan
                                active={chosen === 1}
                                src={planniePlus}
                                planId={1}/>
                            <Plan
                                active={chosen === 2}
                                src={plannieTeam}
                                planId={2}/>
                            <Plan
                                active={chosen === 3}
                                src={plannieGold}
                                planId={3}/>
                        </Row>
                    </Row>
                </Col>
            </div>
        </ThemeProvider>
    )
}


export default AgentSubscription
