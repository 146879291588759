import Cookies from "universal-cookie";

const PRODUCTION_URL = "https://plannie-monitor-service.herokuapp.com/";
const QA_URL = "https://plannie-monitor-service-qa.herokuapp.com/";
const LOCALHOST = "http://localhost:3107/";
const CW_QA_URL = "https://fast-basin-58167.herokuapp.com/";
const CW_PROD_URL = "https://plannieapp.com/"

export default class Network {

    static instance = null;

    baseUrl = this.isLocalHost() ? QA_URL : PRODUCTION_URL;
    cwUrl = this.isLocalHost() ? CW_QA_URL : CW_PROD_URL;
    cookies = new Cookies();
    user = null;

    static getInstance() {
        if (Network.instance == null) {
            Network.instance = new Network();
        }

        return this.instance;
    }

    getBaseUrl(env) {
        if (!!env) {
            switch (env) {
                case "qa":
                    return QA_URL;
                case "prod":
                    return PRODUCTION_URL;
                case "local":
                    return LOCALHOST;
            }
        }

        return this.baseUrl;
    }

    getCWBaseUrl() {
        return this.cwUrl
    }

    getCookies() {
        return this.cookies;
    }

    setUser(user) {
        this.user = user;
    }

    getUser() {
        return this.user;
    }

    isLocalHost() {
        return window.location.hostname === 'qa-monitor.plannieapp.com' ||
            window.location.hostname === 'localhost' ||
            // [::1] is the IPv6 localhost address.
            window.location.hostname === '[::1]' ||
            // 127.0.0.1/8 is considered localhost for IPv4.
            window.location.hostname.match(
                /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
            )

    }

    removeCookie(key) {
        let cookieOptions = {
            "secure": false,
            "httpOnly": false,
            "path": "/"
        };

        this.cookies.remove(key, cookieOptions);
    }

    setCookie(key, value) {
        let now = new Date();
        let twentyFourHours = 24 * 60 * 60 * 1000;
        let oneYear = 365 * 24 * 60 * 60 * 1000;
        // let expirationTime = privileges === 'admin' ? twentyFourHours : oneYear;
        let then = new Date(new Date().setMilliseconds(now.getMilliseconds() + oneYear));
        let cookieOptions = {
            "secure": false,
            "httpOnly": false,
            "expires": then,
            "path": "/"
        };

        this.cookies.set(key, value, cookieOptions);
    }

    removeAuthentication() {
        console.log("removeAuthentication");
        this.removeCookie('isAuthenticated');
        localStorage.removeItem('user');
        window.location.reload();
    }

    getHeaders() {
        if (this.user === null) {
            this.restoreUser();
            if (this.user === null) {
                this.removeAuthentication();
                return;
            }
        }

        return {
            "Content-Type": "application/json",
            "x-plannie-token": this.user.internalToken,
            "x-plannie-userId": this.user.userId
        };
    }

    isTokenExpiredOrInvalid(responseCode) {
        return responseCode === 401 || responseCode === 403
    }

    restoreUser() {
        let user = localStorage.getItem('user');
        try {
            this.user = JSON.parse(user);
        } catch (e) {
            this.removeAuthentication();
        }
    }

}
